( ->
  angular.module("oneq.forms")
  .directive('ngCheckRequired', ->
    require: 'ngModel',
    restrict: 'A',
    link: (scope, element, attrs, ngModel) ->
      console.log("Checking to see if required")
      ngModel.$validators.checkRequired = (modelValue, viewValue) ->
        value = modelValue || viewValue
        match = scope.$eval(attrs.ngTrueValue) || true
        value && match is value

  )
)()
