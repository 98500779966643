CountryService = (OneQClient) ->
  baseUrl = "/web/api/countries/v1"

  availableCountries: () ->
    OneQClient.get("#{baseUrl}/available")
      .then((response) -> response.data)

  isAllowed: () ->
    OneQClient.get("#{baseUrl}/allowed")
      .then((response) -> response.data)

CountryService.$inject = ['OneQClient']

module.exports = CountryService
