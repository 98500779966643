angular = require 'angular'

ParentalConsentService = require './parental-consent.service'
ParentalConsentForm = require './parental-consent.component'
ParentalConsentSuccess = require './parental-consent-success.component'
RouterConfig = require './parental-consent.router'

module.exports = angular.module('oneq.member.parental-consent', [])
  .config(RouterConfig)
  .factory('ParentalConsentService', ParentalConsentService)
  .component('parentalConsentForm', ParentalConsentForm)
  .component('parentalConsentSuccess', ParentalConsentSuccess)
  .name
