insertIntoArray = (array, atIndex, element) ->
    [
        array.slice(0, atIndex)...
        element
        array.slice(atIndex)...
    ]

removeFromArray = (array, index) ->
    [
        array.slice(0, index)...
        array.slice(index + 1)...
    ]

updatedArray = (array, atIndex, element) ->
    [
        array.slice(0, atIndex)...
        element
        array.slice(atIndex + 1)...
    ]

shiftElementInArray = (array, newIndex, element) ->
    oldIndex = array.findIndex((i) -> i is element)
    updatedArray = removeFromArray(array, oldIndex)
    insertIntoArray(updatedArray, newIndex, element)

module.exports = {insertIntoArray, removeFromArray, updatedArray, shiftElementInArray}
