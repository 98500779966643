Actions = require './parental-consent.action.creators'

initialLoad = ($ngRedux, $stateParams) ->
  $ngRedux.dispatch(Actions.initialLoad($stateParams.hash))

initialLoad.$inject = ['$ngRedux', '$stateParams']

RouterConfig = ($urlRouterProvider, $stateProvider) ->
  $stateProvider
    .state(
      name: 'oneq.member.parental-consent'
      url: '/members/parental-consent/:hash'
      resolve: { initialLoad }
      views:
        'content@oneq':
          component: 'parentalConsentForm'
    )
    .state(
      name: 'oneq.member.parental-consent-success'
      url: '/members/parental-consent-success'
      params:
        message: null
      views:
        'content@oneq':
          component: 'parentalConsentSuccess'
    )

RouterConfig.$inject = ['$urlRouterProvider', '$stateProvider']

module.exports = RouterConfig
