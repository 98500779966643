module.exports =
  UNDER_18_TERMS: 'under18Terms'
  CHARITY: 'charity'
  FIRST_NAME: 'firstName'
  LAST_NAME: 'lastName'
  EMAIL: 'email'
  COUNTRY: 'country'
  PHONE: 'phone'
  BIRTH_MONTH: 'birthMonth'
  BIRTH_DAY: 'birthDay'
  BIRTH_YEAR: 'birthYear'
  STREET_ADDRESS: 'streetAddress'
  POSTAL_CODE: 'postalCode'
  MEMBER_PAYMENT: 'memberPayment'
  CHILD_INFO: 'childInfos'
