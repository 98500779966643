_             = require 'lodash'
Actions       = require '../actions'

module.exports = (state={submitting: false}, action) ->
  switch action.type
    when Actions.OPENING
      _.omit(state, ['errorMessage'])

    when Actions.UPDATE_PARENT_EMAIL
      _.assign({}, state, {
        parentEmail: action.payload
      })

    when Actions.PARENT_EMAIL_SENDING
      _.assign({}, state, {
        submitting: true
      })

    when Actions.PARENT_EMAIL_SUCCESS
      _.assign({}, _.omit(state, ['errorMessage']), {
        submitting: false,
        successMessage: action.payload
      })

    when Actions.PARENT_EMAIL_FAILURE
      _.assign({}, _.omit(state, ['successMessage']), {
        submitting: false,
        errorMessage: action.payload
      })

    else state
