_                   = require 'lodash'
{ createSelector }  = require 'reselect'
FormSelectors       = require 'common/forms/form.selectors'
Fields              = require './fields'
ConsentFields       = require 'member/components/consent/consent.fields'

download = (state) -> state.member.register.download
form = createSelector(
  download,
  (download) -> download.form
)

values = FormSelectors.values(form)
options = FormSelectors.options(form)
displayValues = FormSelectors.displayValues(form)

coreFieldNames = _.values(Fields)
consentMeta = [
  ConsentFields.Phone
]
consentFieldNames = _.map(consentMeta, 'name')
domainModel = createSelector(
  values,
  (values) ->
    _.assign(
      _.pick(values, coreFieldNames),
      {
        consent: {
          consentingToMap: _.pick(values, consentFieldNames),
          alreadyConsentedTo: []
        }
      }
    )
)

successMessage = createSelector(
  download,
  (download) -> download.successMessage
)

module.exports = {
  form,
  consentMeta,
  successMessage,
  options,
  displayValues,
  domainModel
}
