angular = require 'angular'

VerificationCode = require './verification-code.component'
ParentEmail = require './parent-email.component'
ChildInfoComponent = require './child-info/child.info.view'

RouterConfig = require './profile.router'
ProfileService = require './profile.service'
MemberConsentModule = require '../components/consent/consent.module'

module.exports = angular.module('oneq.member.profile', [])
  .config(RouterConfig)
  .factory('ProfileService', ProfileService)
  .factory('VerificationCodeLauncher', VerificationCode.Launcher)
  .factory('ParentalEmailLauncher', ParentEmail.Launcher)
  .component('profileCharities', require('./profile-charities.component'))
  .component('profileReferralLink', require('./profile-referral-link.component'))
  .component('profileDeactivate', require('./profile-deactivate.component'))
  .component('verificationCode', VerificationCode.Component)
  .component('parentalEmail', ParentEmail.Component)
  .component('profile', require('./profile.component'))
  .component('singleChildInfo', ChildInfoComponent)
  .name
