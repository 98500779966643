
JwtSecurityModule = require 'common/security/jwt/jwt.security.module'
SessionTrackingModule = require 'common/network/session.tracking.module'
SessionTimeoutHandler = require 'common/network/session-timeout-handler'

OneQClient = ($http, $q, $window, OneQSessionRefresh, OneQSessionHandler, JwtSecurityFactory, SessionTrackingFactory) ->
  client = {}

  redirectToLogin = (ref) =>
    JwtSecurityFactory.removeExpiresAt()
    SessionTrackingFactory.removeAllTrackingFields()
    redirect = ref or ($window.location.pathname + $window.location.hash)
    path =
      if redirect and redirect isnt '/users/login'
        encodeURI("/users/login?ref=#{redirect}")
      else
        '/users/login'
    $window.location = path

  badGatewayError = (deferred) =>
    deferred.reject(
      status: 502
      data:
        errors: ["Server is either slow or under maintenance.  Please try again in a few minutes.  Thanks for your patience."]
    )

  notFoundError = (deferred) =>
    deferred.reject(
      status: 404
      data:
        errors: ["Not Found"]
    )

  forbiddenError = (deferred) =>
    deferred.reject(
      status: 403
      data:
        errors: ["You do not have permission to perform this task"]
    )

  checkSessionExpiration = (ref, deferred) =>
    console.log("Received 403.  Checking to see if session timed out")
    OneQSessionRefresh.refresh()
      .then((success) =>
        #We originally recieved forbidden, but we know user is still logged in.  So throw original forbidden error
        forbiddenError(deferred)
      ).catch((error) =>
        #We originally received forbidden, and know user is no longer logged in.
        redirectToLogin(ref)
      )

  withRedirect = (promise, ref) ->
    deferred = $q.defer()
    promise.then(
      (response) ->
        OneQSessionHandler.setNetworkActivity(true)
        OneQSessionHandler.checkJwtSessionExpiration(response)
        SessionTrackingFactory.parseHeaderTrackingFields(response)
        deferred.resolve(response)
      , (error) ->
        if (error.status is 401)
          redirectToLogin(ref)
        else if (error.status is 403)
          checkSessionExpiration(ref, deferred)
        else if (error.status is 404)
          notFoundError(deferred)
        else if (error.status is 502)
          badGatewayError(deferred)
        else
          deferred.reject(error)
    )
    deferred.promise


  for method in ['head', 'get', 'delete', 'jsonp']
    do (method) ->
      client[method] = (url, config) ->
        withRedirect($http[method](url, config), config?.ref)

  for method in ['put', 'post', 'patch']
    do (method) ->
      client[method] = (url, data, config) ->
        withRedirect($http[method](url, data, config), config?.ref)


  client

OneQClient.$inject = ['$http', '$q', '$window', 'OneQSessionRefresh', 'OneQSessionHandler', 'JwtSecurityFactory', 'SessionTrackingFactory']

angular.module('oneq.client', [
    JwtSecurityModule
    SessionTrackingModule
    SessionTimeoutHandler
  ])
  .factory('OneQClient', OneQClient)
  .run(['OneQSessionHandler', (OneQSessionHandler) ->
    OneQSessionHandler.initiateSessionHandling()
  ])