Namespace = require './namespace'

namespaced = (value) -> "#{Namespace}/#{value}"

childInfoLoaded = namespaced('CHILD_INFO_LOADED')
childAgeYearChanged = namespaced("CHILD_AGE_YEAR_CHANGED")
childAgeMonthChanged = namespaced("CHILD_AGE_MONTH_CHANGED")
childGenderChanged = namespaced("CHILD_GENDER_CHANGED")
childInfoAdded = namespaced("CHILD_INFO_ADDED")
childInfoRemoved = namespaced("CHILD_INFO_REMOVED")

module.exports =
  INITIAL_LOAD:  namespaced('INITIAL_LOAD')
  CHILD_INFO_LOADED: childInfoLoaded
  CHILD_AGE_YEAR_CHANGED: childAgeYearChanged
  CHILD_AGE_MONTH_CHANGED: childAgeMonthChanged
  CHILD_GENDER_CHANGED: childGenderChanged
  CHILD_INFO_ADDED: childInfoAdded
  CHILD_INFO_REMOVED: childInfoRemoved
  SUBMIT: namespaced('SUBMIT')
  PARENT_EMAIL_OPENING: namespaced('OPENING')
  UPDATE_PARENT_EMAIL: namespaced('UPDATE_PARENT_EMAIL')
  PARENT_EMAIL_SENDING: namespaced('PARENT_EMAIL_SENDING')
  PARENT_EMAIL_SUCCESS: namespaced('PARENT_EMAIL_SUCCESS')
  PARENT_EMAIL_FAILURE: namespaced('PARENT_EMAIL_FAILURE')
  CHILD_INFO_ACTIONS: [childInfoLoaded, childAgeYearChanged, childAgeMonthChanged, childGenderChanged, childInfoAdded, childInfoRemoved]
