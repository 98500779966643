_                                             = require 'lodash'
{ createSelector, createStructuredSelector }  = require 'reselect'

# create a map of { <fieldName>: { <optionValue>: <optionLabel> } } that can be used
# to generate options for a select
options = (formSelector) ->
  createSelector(
    formSelector,
    (form) ->
      fields = form?.fields || {}
      selectFields = _.pickBy(fields, (field) -> _.has(field, 'valueField'))

      _.mapValues(selectFields, (field) ->
        _.reduce(field.options, (accum, option) ->
          label = option[field.labelField]
          value = option[field.valueField] || ""

          entry = {}
          entry[value] = label
          _.assign({}, accum, entry)

        , {})
      )
  )

# create a map of { <fieldName>: <value> }
values = (formSelector) ->
  createSelector(
    formSelector,
    (form) ->
      _.omitBy(
        _.mapValues(form?.fields || {}, 'value'),
        (value) -> _.isNil(value) || (_.isString(value) && _.isEmpty(value))
      )
  )

displayValues = (formSelector) ->
  createSelector(
    formSelector,
    (form) ->
      fields = form?.fields || {}
      selectFields = _.pickBy(fields, (field) -> _.has(field, 'valueField'))
      inputFields = _.omit(fields, _.keys(selectFields))

      _.assign(
        _.mapValues(selectFields, (field) -> field.value?.toString() || ""),
        _.mapValues(inputFields, 'value')
      )
  )

module.exports = {
  options,
  values,
  displayValues
}
