_       = require 'lodash'
Actions = require './form.actions'

module.exports = (namespace) ->
  submittingForm: ->
    {
      type: Actions.SUBMITTING_FORM
      payload: { namespace }
    }

  initField: (field) -> (updates) ->
    {
      type: Actions.FORM_FIELD_INIT,
      payload: _.assign({ field, namespace }, updates)
    }

  fieldChanged: (field, convert = _.identity) -> (value) ->
    {
      type: Actions.FORM_FIELD_CHANGED,
      payload: {
        field,
        namespace,
        value: convert(value)
      }
    }
