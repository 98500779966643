class ProfileCharitiesController
  constructor: () ->
    @showList = false

  $onChanges: () ->
    if @charities
      @charity = @charities.find((charity) => charity.id == @charityId)

  show: ->
    @showList = true

  hide: ->
    @showList = false

  selectCharity: (charity) ->
    @hide()
    @charityChanged({ value: charity.id || "" })

ProfileCharitiesController.$inject = []

module.exports =
  bindings:
    charities: '<'
    charityId: '<'
    totalDonatedCount: '<'
    todayDonatedCount: '<'
    charityChanged: '&'
  controller: ProfileCharitiesController
  template: require('./profile-charities.template.html')
