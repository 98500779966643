ScrollToTop = ($transitions) ->
    $transitions.onSuccess({}, ->
        angular.element('body,html').animate(
            scrollTop: 0
            , 1000)
    )
ScrollToTop.$inject = ['$transitions']

AdHocScrollToTop = () ->
  scroll: () =>
    angular.element('body,html').animate(
        scrollTop: 0
        , 1000)

module.exports =
  scrollToTopTransition: ScrollToTop
  adHocTransition: AdHocScrollToTop
