_                   = require 'lodash'
injected            = require 'common/redux/injected'
FormActionCreators  = require 'common/forms/form.action.creators'
LoadingActions      = require 'common/layout/loading.action.creators'
ConsentFields       = require '../../components/consent/consent.fields'
Actions             = require './actions'
Fields              = require './fields'
Selectors           = require './selectors'
Namespace           = require './namespace'

FormActions = FormActionCreators(Namespace)

module.exports =
  downloadCountryChanged: FormActions.fieldChanged(Fields.DOWNLOAD_COUNTRY)

  mobileChanged: FormActions.fieldChanged(Fields.MOBILE)

  consentChanged: (field, value) ->
    action = FormActions.fieldChanged(field)(value)
    action.payload.userUnder18 = false
    action

  initialLoad: ->
    (dispatch, getState, extraArgs) ->
      CountryService = injected(extraArgs, 'CountryService')

      dispatch(
        LoadingActions.loadingData( ->
          CountryService.availableCountries()
            .then(
              (availableCountries) ->
                dispatch(
                  FormActions.initField(Fields.DOWNLOAD_COUNTRY)(
                    options: availableCountries
                    value: 'usa'
                  )
                )

                availableCountries
            )
        )
      )

  submit: ->
    (dispatch, getState, extraArgs) ->
      RegisterService = injected(extraArgs, 'RegisterService')

      dispatch(FormActions.submittingForm())

      state = getState()
      form = Selectors.form(state)

      if form.isValid
        model = Selectors.domainModel(state)

        dispatch(
          LoadingActions.loadingData( ->
            RegisterService.sendDownloadLink(model)
              .then((successMessage) ->

                dispatch(
                  type: Actions.SUBMIT
                  payload: successMessage
                )

                successMessage
              )
          )
        )
