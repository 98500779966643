Actions = require './action.creators'

initialLoad = ($ngRedux) ->
  $ngRedux.dispatch(Actions.initialLoad())

initialLoad.$inject = ['$ngRedux']

RouterConfig = ($stateProvider) ->
  $stateProvider
    .state(
      name: 'oneq.member.profile'
      url: '/members/profile'
      resolve:
        initialLoad: initialLoad
      views:
        'content@oneq':
          component: 'profile'
    )

RouterConfig.$inject = ['$stateProvider']

module.exports = RouterConfig
