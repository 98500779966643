module.exports =
  Email:
    name: "emailConsent"
    message: "I consent to the use of my email address for PayPal, 1Q notifications, and information validation"
    detailMessage: "We use your email to send you payments directly via PayPal. We will NEVER share your email with customers"
    validationMessage: "We can't pay you without your email address. We will NEVER share this with customers or 3rd Party marketing services"
  Phone:
    name: "phoneConsent"
    message: "I consent to the use of my phone # for receiving questions, fraud detection, and information validation"
    detailMessage: "We use your phone number to send you questions and to help protect our member base by ensuring those who sign up actually own that phone #.  We will NEVER share your phone number with customers"
    validationMessage: "Unfortunately we can't send questions without your phone number, even if using the app. We will NEVER share this with customers or 3rd party marketing services"
  Demo:
    name: "demoConsent"
    message: "I consent to the use of my demographics so questions can be targeted and analyzed. My demographics will always remain anonymous."
    detailMessage: "Our customers send questions to our member based on the demographics"
    validationMessage: "Our customers send questions to members based on their demographics.  We won't be able to send any questions without this information.  We will NEVER include your demographics along with your personal data to any customer or 3rd party marketing services"
  Terms:
    name: "termsConsent"
    message: "I agree to the 1Q Terms and Privacy Policy"
    validationMessage: "Thou shall not pass without accepting the terms and conditions"
  PaypalUnder18:
    name: "payPalUnder18Consent"
    message: "I confirm the PayPal account I have supplied is registered to someone 18 or over."
    detailMessage: "You have indicated that you are under the age of 18. 1Q sends payments to users via Paypal.  Paypal does not allow anyone under the age of 18 to have a Paypal account."
    validationMessage: "Thou shall not pass without verifying this PayPal email is owned by someone 18 or older."
