( ->
  class OneQAlertController
    $onInit: ->
      @messages = @resolve.messages
      @iconClasses = @resolve.iconClasses.join(' ')
      @messageClasses = @resolve.messageClasses.join(' ')
      @topLevelClasses = @resolve.topLevelClasses.join(' ')
      @title = @resolve.title
      @confirmConfig = @resolve.confirm

    ok: -> @close({ $value: @confirmConfig.value })

  class OneQConfirmController
    $onInit: ->
      @messages = @resolve.messages
      @iconClasses = @resolve.iconClasses.join(' ')
      @messageClasses = @resolve.messageClasses.join(' ')
      @topLevelClasses = @resolve.topLevelClasses.join(' ')
      @title = @resolve.title or 'Confimation'
      @confirmConfig = @resolve.confirm
      @cancelConfig = @resolve.cancel

    confirm: ->
      @close({ $value: @confirmConfig.value })

    cancel: ->
      @close({ $value: @cancelConfig.value })


  angular.module('oneq.forms')
    .component('oneqAlert',
      bindings:
        resolve: '<'
        close: '&',
        dismiss: '&'
      controller: OneQAlertController
      template: '''
        <div class="{{$ctrl.topLevelClasses}}">
          <div class="modal-header">
            <h4><i class="{{$ctrl.iconClasses}}"> </i> {{$ctrl.title}}</h4>
          </div>
          <div class="modal-body">
            <ul class="{{$ctrl.messageClasses}}">
              <li ng-repeat="message in $ctrl.messages track by $index">{{message}}</li>
            </ul>
          </div>
          <div class="modal-footer">
              <button class="flat-button gray medium" type="submit" ng-click="$ctrl.ok()">{{::$ctrl.confirmConfig.text}}</button>
          </div>
        </div>
      '''
    )
    .component('oneqConfirm',
      bindings:
        resolve: '<'
        close: '&',
        dismiss: '&'
      controller: OneQConfirmController
      template: '''
        <div class="{{$ctrl.topLevelClasses}}">
          <div class="modal-header">
            <h4><i class="{{$ctrl.iconClasses}}"> </i> {{$ctrl.title}}</h4>
          </div>
          <div class="modal-body">
            <ul class="{{$ctrl.messageClasses}}">
              <li ng-repeat="message in $ctrl.messages track by $index" data-testid="message-{{$index}}">{{message}}</li>
            </ul>
          </div>
          <div class="modal-footer">
              <button class="flat-button gray medium" ng-click="$ctrl.cancel()">{{::$ctrl.cancelConfig.text}}</button>
              <button class="flat-button medium" ng-click="$ctrl.confirm()" data-testid="modal-confirm">{{::$ctrl.confirmConfig.text}}</button>
          </div>
        </div>
      '''
    )
    .factory('OneQAlerts', ['$uibModal', '$timeout', ($uibModal, $timeout) ->
      defaults =
        iconClasses: []
        messageClasses: []
        size: 'sm'

      alertDefaults =
        confirm:
          text: 'Ok'
          value: 'ok'

      dialogDefaults =
        confirm:
          text: 'Confirm'
          value: 'yes'
        cancel:
          text: 'Cancel'
          value: 'no'

      open = (messages, options, comp='oneqAlert') ->
        instance = $uibModal.open(
          component: comp
          size: options.size
          resolve: {
            iconClasses: () -> options.iconClasses
            topLevelClasses: () -> options.topLevelClasses
            messageClasses: () -> options.messageClasses
            messages: () -> messages or []
            title: () -> options.title
            confirm: () -> options.confirm
            cancel: () -> options.cancel
          }
        )

        if options.dismissTimeout? and typeof options.dismissTimeout is 'number'
          $timeout(() ->
            instance.dismiss()
          , options.dismissTimeout)

        instance

      info: (messages, options={}) ->
        open(messages, _.defaults(options, alertDefaults, { iconClasses: ['fa', 'fa-info-circle', 'fa-lg'], topLevelClasses: ['info'] }, defaults))

      warning: (messages, options={}) ->
        open(messages, _.defaults(options, alertDefaults, { iconClasses: ['fa', 'fa-exclamation-triangle', 'fa-lg'], topLevelClasses: ['warning'] }, defaults))

      error: (messages, options={}) ->
        open(messages, _.defaults(options, alertDefaults, { iconClasses: ['fa', 'fa-exclamation-circle', 'fa-lg'], topLevelClasses: ['error'] }, defaults))

      success: (messages, options={}) ->
        open(messages, _.defaults(options, alertDefaults, { iconClasses: ['fa', 'fa-check-circle', 'fa-lg'], topLevelClasses: ['success'] }, defaults))

      confirm: (messages, options={}) ->
        open(messages, _.defaults(options, dialogDefaults, { iconClasses: ['fa', 'fa-exclamation-circle', 'fa-lg'], topLevelClasses: ['confirm'] }, defaults), 'oneqConfirm')
    ])
)()
