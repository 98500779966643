OneQSessionEventService = ($rootScope) ->
  broadcastJwtExpiresChange: () => $rootScope.$broadcast('jwt-expires-at-changed')
  broadcastNetworkActivityChange: () => $rootScope.$broadcast('network-activity-changed')
  #Do NOT call this from a controller.  Since its listening on rootScope, there is potential memory leak (e.g. controller gets destroyed, and re-initted)
  onJwtExpiresChange: (callback) => $rootScope.$on('jwt-expires-at-changed', callback)
  onNetworkActivityChange: (callback) => $rootScope.$on('network-activity-changed', callback)

OneQSessionEventService.$inject = ["$rootScope"]

OneQSessionRefresh = ($http) ->
  refresh: () =>
    $http.get("/web/api/security/v1/currentSession/health")

  refreshToken: () =>
    $http.post("/web/api/security/v1/currentSession/refresh-token", {})

OneQSessionRefresh.$inject = ["$http"]

OneQSessionHandler = ($timeout, OneQSessionRefresh, OneQSessionEventService, JwtSecurityFactory) ->
  
  jwtRefreshTokenTimeout = undefined
  NETWORK_ACTIVITY_BIT = "x-session-network-activity"

  refreshJwtTokenTimeouts = () =>
    $timeout.cancel(jwtRefreshTokenTimeout) if jwtRefreshTokenTimeout
    millisUntilJwtExpirationWithBuffer = JwtSecurityFactory.millisUntilExpirationWithBuffer()
    if (millisUntilJwtExpirationWithBuffer > 0) 
      expiresAtWithRandomBuffer = getExpiresAtWithRandomBuffer(millisUntilJwtExpirationWithBuffer)
      console.log("Refreshing JWT Timeout with #{expiresAtWithRandomBuffer} millis")
      jwtRefreshTokenTimeout = $timeout(refreshJwtToken, expiresAtWithRandomBuffer) 
    else
      JwtSecurityFactory.removeExpiresAt()
      
  getExpiresAtWithRandomBuffer = (max) =>
      max - Math.floor(Math.random() * 1000) #Stagger calls with a second random buffer to we dont get refresh calls from 

  refreshJwtToken = (forceRefresh) =>
    console.log("Refreshing Jwt Token")
    if getNetworkActivity() or forceRefresh
      OneQSessionRefresh.refreshToken().then((response) =>
        setNetworkActvityBit(false)
        checkJwtSessionExpiration(response)
      )
    else 
      console.log("Not refreshing JWT since there has been no network activity since the last refresh")

  setNetworkActvityBit = (bit) =>
    localStorage.setItem(NETWORK_ACTIVITY_BIT, bit.toString())
    OneQSessionEventService.broadcastNetworkActivityChange if bit

  getNetworkActivity = () =>
    activity = localStorage.getItem(NETWORK_ACTIVITY_BIT)
    activity and JSON.parse(activity)

  checkJwtSessionExpiration = (response) =>
    jwtEat = JwtSecurityFactory.parseHeader(response)
    if jwtEat and jwtEat != JwtSecurityFactory.getExpiresAt()
      JwtSecurityFactory.setExpiresAt(jwtEat)
      refreshJwtTokenTimeouts()
      OneQSessionEventService.broadcastJwtExpiresChange()

  initiateSessionHandling = () =>
    refreshJwtTokenTimeouts()

  initiateSessionHandling: initiateSessionHandling
  checkJwtSessionExpiration: checkJwtSessionExpiration
  setNetworkActivity: setNetworkActvityBit
  getNetworkActivity: getNetworkActivity
  refreshJwtToken: refreshJwtToken

OneQSessionHandler.$inject = ['$timeout', 'OneQSessionRefresh', 'OneQSessionEventService', 'JwtSecurityFactory']

OneQSessionModalHandler = ($modal, $timeout, JwtSecurityFactory, OneQSessionHandler, OneQSessionEventService) ->
  
  idleTimeout = undefined
  sessionTimeout = undefined
  warningModal = undefined

  refreshModalTimeouts = () =>
    cancelModalTimeouts()
    expiresAtWithBufferMillis = JwtSecurityFactory.millisUntilExpirationWithBuffer()
    idleMilliseconds = expiresAtWithBufferMillis - 1 * 60 * 1000 #Show Idle dialog 1 min before expiration
    console.log("Setting idle timeout for #{idleMilliseconds}")
    idleTimeout = $timeout(onIdleTimeout, idleMilliseconds)
    console.log("Setting session timeout for #{expiresAtWithBufferMillis}")
    sessionTimeout = $timeout(onSessionTimeout, expiresAtWithBufferMillis)

  cancelModalTimeouts = () =>
    console.log("Cancelling Modal Timeouts")
    $timeout.cancel(idleTimeout) if idleTimeout
    $timeout.cancel(sessionTimeout) if sessionTimeout

  onIdleTimeout = () =>
    console.log("Idle timeout has been called with Network Activity bit of #{OneQSessionHandler.getNetworkActivity()}")
    unless OneQSessionHandler.getNetworkActivity()
      displayWarningModal().result
        .then(=> OneQSessionHandler.refreshJwtToken(true)) #Keep logic for now to allow a manual refresh of tokens if user is on ask page
        .catch((error) => console.log("Error in Refreshing Session with #{error}"))

  onSessionTimeout = () =>
    console.log("Session timeout has been called with Network Activity bit of #{OneQSessionHandler.getNetworkActivity()}")
    unless OneQSessionHandler.getNetworkActivity()
      warningModal?.dismiss("Client-Session Session Checks Timed Out")
      displaySessionTimeoutModal()

  displayWarningModal = () =>
    warningModal = $modal.open(
      component: 'sessionIdleDialog'
      resolve:
        secondsLeft: -> JwtSecurityFactory.millisUntilExpirationWithBuffer() / 1000
    )
    warningModal

  displaySessionTimeoutModal = () =>
    $modal.open(
      component: 'sessionTimedOutDialog'
    ).result.catch(angular.noop)

  OneQSessionEventService.onJwtExpiresChange( => 
    refreshModalTimeouts()
  )

  OneQSessionEventService.onNetworkActivityChange( =>
    cancelModalTimeouts() #If Network Activity bit is set, then refresh token will be called automatically
  )

  startSessionWatch = () =>
    refreshModalTimeouts()

  startSessionWatch: startSessionWatch
  onIdleTimeout: onIdleTimeout
  onSessionTimeout: onSessionTimeout


OneQSessionModalHandler.$inject = ["$uibModal", "$timeout", "JwtSecurityFactory", "OneQSessionHandler", "OneQSessionEventService"]


class SessionIdleDialogController
  constructor: (@$interval) ->

  $onInit: ->
    @secondsLeft = @resolve?.secondsLeft
    console.log("Showing idle dialog with #{@secondsLeft} secondsLeft")
    @secondTick = @$interval(=>
      @secondsLeft--
      @minutesDisplay = Math.floor(@secondsLeft / 60)
      @secondsDisplay = Math.floor(@secondsLeft % 60)
    , 1000, @secondsLeft)

  resume: =>
    @close(
      $value: "ok"
    )

  $onDestroy: =>
    @$interval.cancel(@secondTick)

SessionIdleDialogController.$inject=['$interval']

class SessionTimedOutController
  constructor: (@$location) ->

  $onInit: =>
    absUrl = @$location.absUrl()
    host = @$location.host()
    endOfHost = absUrl.indexOf(host) + host.length
    relative = absUrl.substring(endOfHost)
    @loginURL = "/users/login?ref=#{encodeURIComponent(relative)}"

SessionTimedOutController.$inject = ["$location"]

SessionIdleDialogComponent =
  controller: SessionIdleDialogController
  template: require './session.idle.dialog.template.html'
  bindings:
    close: '&'
    resolve: '<'

SessionTimedOutComponent =
  controller: SessionTimedOutController
  template: require './session.timed.out.dialog.template.html'

module.exports =
  angular.module('oneq.client.session.handling', ['oneq.client.security.jwt'])
    .factory('OneQSessionHandler', OneQSessionHandler)
    .factory('OneQSessionRefresh', OneQSessionRefresh)
    .factory('OneQSessionModalHandler', OneQSessionModalHandler)
    .service('OneQSessionEventService', OneQSessionEventService)
    .component('sessionIdleDialog', SessionIdleDialogComponent)
    .component('sessionTimedOutDialog', SessionTimedOutComponent)
    .name
