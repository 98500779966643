class ConsentEntryController
  constructor: (@$ngRedux) ->

  $onChanges: (changes) ->
    # b/c angular is stupid and $onChanges is called before $onInit
    if changes.form.isFirstChange()
      @fieldId = @meta.name
      @message = @meta.message
      @detailMessage = @meta.detailMessage

      if @fieldId in (@alreadyConsentedTo || [])
        @hidden = true

    field = @form.fields[@fieldId]

    @submitted = @form.submitted
    @value = field.value
    @isValid = field.isValid
    @touched = field.touched
    @errors = field.errors

  toggle: (value) ->
    @onUpdate({ field: @fieldId,  value })

ConsentEntryController.$inject = ['$ngRedux']

module.exports =
  bindings:
    alreadyConsentedTo: '<'
    form: '<'
    meta: '<'
    onUpdate: '<'
  controller: ConsentEntryController
  template: require('./consent-entry.template.html')
