_                   = require 'lodash'
injected            = require 'common/redux/injected'
LoadingActions      = require 'common/layout/loading.action.creators'
SessionActions      = require 'common/layout/session.action.creators'
RouterActions       = require 'common/layout/router.action.creators'
FormActionCreators  = require 'common/forms/form.action.creators'
ConsentFields       = require '../../components/consent/consent.fields'
Actions             = require './actions'
Fields              = require './fields'
Selectors           = require './selectors'
Namespace           = require './namespace'

FormActions = FormActionCreators(Namespace)

module.exports =
  firstNameChanged: FormActions.fieldChanged(Fields.FIRST_NAME)
  lastNameChanged: FormActions.fieldChanged(Fields.LAST_NAME)
  mobileChanged: FormActions.fieldChanged(Fields.MOBILE)
  emailChanged: FormActions.fieldChanged(Fields.EMAIL)
  passwordChanged: FormActions.fieldChanged(Fields.PASSWORD)
  consentChanged: (field, value) ->
    action = FormActions.fieldChanged(field)(value)
    action.payload.userUnder18 = false

    action

  submit: ->
    (dispatch, getState, extraArgs) ->
      RegisterService = injected(extraArgs, 'RegisterService')

      dispatch(FormActions.submittingForm())

      state = getState()
      form = Selectors.form(state)

      if form.isValid
        model = Selectors.domainModel(state)

        dispatch(
          LoadingActions.loadingData( ->
            RegisterService.register(model)
              .then( -> SessionActions.updateSession()(dispatch, getState, extraArgs))
              .then( -> dispatch(RouterActions.stateGo('oneq.member.profile')))
          )
        )

  login: ->
    (dispatch, getState, extraArgs) ->
      FacebookService = injected(extraArgs, 'FacebookService')

      dispatch(
        LoadingActions.loadingData( ->
          FacebookService.register()
            .then( -> SessionActions.updateSession()(dispatch, getState, extraArgs))
            .then( -> dispatch(RouterActions.stateGo('oneq.member.profile')))
        )
      )
