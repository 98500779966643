RouterConfig = ($stateProvider) ->
  $stateProvider
    .state(
      name: 'oneq.member'
      abstract: true
    )

RouterConfig.$inject = ['$stateProvider']

module.exports = RouterConfig
