angular = window.angular = require 'angular'

RouterConfig = require './root.router'
{ LayoutComponent } =  require './layout.component'
HeaderNavFactory = require 'common/layout/header/header-nav.factory'
SecurityModule = require 'common/security/security.module'

module.exports = angular.module('oneq.layout', [
    SecurityModule
  ])
  .config(RouterConfig)
  .factory('HeaderNavFactory', HeaderNavFactory)
  .component('ieSux', require('./ie-sux.component'))
  .component('loading', require('./loading.component'))
  .component('avatarMenu', require('./avatar-menu.component'))
  .component('headerNav', require('./header/header-nav.component'))
  .component('footerNav', require('./footer-nav.component'))
  .component('layout', LayoutComponent)
  .name
