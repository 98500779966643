ErrorMessage    = require 'common/util/error-message'
injected        = require 'common/redux/injected'
LoadingActions  = require 'common/layout/loading.action.creators'
Actions         = require './actions'

module.exports =
  initialLoad: ->
    (dispatch, getState, extraArgs) ->
      [$q, ConfirmationService, ReferralService] = injected(extraArgs, [
        '$q',
        'ConfirmationService',
        'ReferralService'
      ])

      LoadingActions.loadingData( ->
        $q.all(
          confirmation: ConfirmationService.confirmation()
          referral: ReferralService.getStatus()
        ).then((payload) ->
          dispatch({
            type: Actions.INITIAL_LOAD
            payload
          })
        )
      )(dispatch, getState, extraArgs)

  resendDownloadLink: ->
    (dispatch, getState, extraArgs) ->
      LoadingActions.loadingData( ->
        injected(extraArgs, 'ConfirmationService').resendDownloadLink()
          .then(
            () ->
              dispatch(
                type: Actions.DOWNLOAD_LINK_SENT
                payload: true
              )
            ,
            (response) ->
              downloadErrorMessage =
                if response.status == 400
                  ErrorMessage.forResponse(response)
                else 'Unexpected Error'

              dispatch(
                type: Actions.DOWNLOAD_LINK_ERROR
                payload: downloadErrorMessage
              )
          )
      )(dispatch, getState, extraArgs)

  selectMinimumPayment: ->
    (dispatch, getState, extraArgs) ->
      LoadingActions.loadingData( ->
        injected(extraArgs, 'ConfirmationService').selectMinimumPayment()
          .then( ->
            dispatch(
              type: Actions.MINIMUM_PAYMENT_SELECTED
              payload: true
            )
          )
      )(dispatch, getState, extraArgs)

  connectToFacebook: ->
    (dispatch, getState, extraArgs) ->
      LoadingActions.loadingData( ->
        injected(extraArgs, 'FacebookService').connect()
          .then( ->
            dispatch(
              type: Actions.REGISTERED_WITH_FACEBOOK
              payload: true
            )
          )
      )(dispatch, getState, extraArgs)
