{ combineReducers }   = require 'redux'
Form                  = require 'common/forms/form.reducers'
FormActions           = require 'common/forms/form.actions'
Validators            = require 'common/forms/validators'
ConsentFields         = require '../components/consent/consent.fields'
Consent               = require '../components/consent/consent.reducers'
Actions               = require './parental-consent.actions'
Namespace             = require './parental-consent.namespace'

form = new Form.Builder()
  .withNamespace(Namespace)
  .withField(ConsentFields.Email.name, Consent.Email())
  .withField(ConsentFields.Phone.name, Consent.Phone())
  .withField(ConsentFields.Demo.name, Consent.Demo())
  .withField(ConsentFields.Terms.name, Consent.Terms())
  .withField(ConsentFields.PaypalUnder18.name, Consent.PaypalUnder18())
  .build()

meta = (state={}, action) ->
  if action.type == Actions.INITIAL_LOAD
    _.assign(state, action.payload.parentalConsentMeta)
  else
    state

module.exports = combineReducers({
  meta,
  form
})
