Actions = require './session.actions'

SecurityService = ({$injector}) ->
  $injector.get('SecurityService')

module.exports =
  updateSession: ->
    (dispatch, getState, extraArgs) ->
      SecurityService(extraArgs).currentSession()
        .then((session) ->
          dispatch(
            type: Actions.SESSION_UPDATED
            payload: session
          )

          session
        )

  sessionEnded: ->
    type: Actions.SESSION_ENDED
