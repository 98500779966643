angular = require 'angular'

FacebookEndpoints = (OneQClientHandler) ->
  baseUrl = "/web/api/facebook/v1"

  config: ->
    OneQClientHandler.get("#{baseUrl}/config")
      .then((response) -> response.data)

  register: (accessToken, userId) ->
    OneQClientHandler.post("#{baseUrl}/register", {
      accessToken: accessToken
      userId: userId
    }).then((response) -> response.data)

  connect: (accessToken, userId) ->
    OneQClientHandler.post("#{baseUrl}/connect", {
      accessToken: accessToken
      userId: userId
    }).then((response) -> response.data)

FacebookEndpoints.$inject = ['OneQClientHandler']

FacebookService = ($q, FacebookEndpoints, loadScript) ->
  connectPermissions = []
  postPermissions = []
  deferred = $q.defer()
  initPromise = deferred.promise

  # Get our config options and use it to create the script tag
  # When the script is done loading, it will call fbAsyncInit,
  # which will complete initPromise
  FacebookEndpoints.config().then((config) ->
    connectPermissions = config.connectPermissions
    postPermissions = config.postPermissions

    window.fbAsyncInit = ->
      FB.init({
        appId      : config.appId
        channelUrl : '//www.1q.com/channel.html' #Channel File
        cookie     : true #enable cookies to allow the server to access the session
        xfbml      : true  #parse XFBML
        version    : config.version
      })

      deferred.resolve(config)

    loadScript('facebook-script', config.scriptUrl)
  )

  withAuth = (perms, block) ->
    initPromise.then((config) ->
      $q((resolve, reject) ->
        opts = { scope: perms.join(',') }
        FB.login((response) ->
          if response && response.status is 'connected' && response.authResponse.accessToken
            block(response.authResponse.accessToken, response.authResponse.userID)
              .then(resolve)
              .catch(reject)
          else
            resolve(null)
        , opts)
      )
    )

  register: () -> withAuth(connectPermissions, FacebookEndpoints.register)
  connect: () -> withAuth(connectPermissions, FacebookEndpoints.connect)

FacebookService.$inject = ['$q', 'FacebookEndpoints', 'loadScript']

module.exports =
  Endpoints: FacebookEndpoints
  Service: FacebookService
