_ = require 'lodash'

ShowIfSecurityDirective = (Predicate) -> (SecurityService) ->
    restrict: 'A'
    link: (scope, element, attrs) ->
        SecurityService.currentSession()
            .then((sess) ->
                unless Predicate(sess)
                    element.remove()
            )

ShowIfLoggedIn = ShowIfSecurityDirective((sess) -> sess.isLoggedIn())
ShowIfLoggedOut = ShowIfSecurityDirective((sess) -> sess.isLoggedOut())
ShowIfLoggedInAsker = ShowIfSecurityDirective((sess) -> sess.isLoggedIn() and sess.isAsker)
ShowIfNotLoggedInAsker = ShowIfSecurityDirective((sess) -> not (sess.isLoggedIn() and sess.isAsker))
ShowIfLoggedOutAndBusiness = ShowIfSecurityDirective((sess) -> sess.isLoggedOut() and sess.isAsker)
ShowIfLoggedOutAndNotBusiness = ShowIfSecurityDirective((sess) -> sess.isLoggedOut() and !sess.isAsker)

inject = (v) ->
    v.$inject = ['SecurityService']
    v

isntPath = ($window) -> (test) ->
    $window.location.pathname isnt test

ShowIfLoggedOutAndNotLoginOrSignUpPage = (SecurityService, $window) ->
    ShowIfSecurityDirective((sess) ->
        check = isntPath($window)
        sess.isLoggedOut() and
            check('/users/login') and
            check('/sign-up')
    )(SecurityService)

ShowIfLoggedOutAndNotLoginOrSignUpPage.$inject = ['SecurityService', '$window']

ShowIfLoggedOutAndNotProfile = (SecurityService, $window) ->
    ShowIfSecurityDirective((sess) ->
        check = isntPath($window)
        sess.isLoggedOut() and
            check('/members/profile')
    )(SecurityService)
ShowIfLoggedOutAndNotProfile.$inject = ['SecurityService', '$window']

module.exports =
    _.assign({},
       {ShowIfLoggedOutAndNotLoginOrSignUpPage, ShowIfLoggedOutAndNotProfile}
    ,
        _.mapValues({ShowIfLoggedIn, ShowIfLoggedOut, ShowIfLoggedInAsker, ShowIfNotLoggedInAsker, ShowIfLoggedOutAndBusiness, ShowIfLoggedOutAndNotBusiness}, inject)
    )
