_                                             = require 'lodash'
{ createSelector }                            = require 'reselect'
Fields                                        = require './fields'
DemographicFields                             = require './demographic.fields'
ConsentFields                                 = require '../components/consent/consent.fields'
FormSelectors                                 = require 'common/forms/form.selectors'

profile = (state) -> state.member.profile
mrp = createSelector(
  profile,
  (profile) -> profile.mrp
)
meta = createSelector(
  profile,
  (profile) -> profile.meta
)
form = createSelector(
  profile,
  (profile) -> profile.form
)
alreadyConsentedTo = createSelector(
  profile,
  (profile) -> profile.alreadyConsentedTo
)
childInfos = createSelector(
  profile,
  (profile) -> profile.childInfos
)
demographics = createSelector(meta,
  (meta) -> meta?.demographics
)
genders = createSelector(demographics,
  (demographics) -> _.filter(demographics?.gender, (g) -> g.value?)
)

formValues = FormSelectors.values(form)
values = createSelector(
  formValues,
  childInfos,
  (formValues, childInfos) ->
    _.assign({}, formValues, {childInfos})
)
# values = FormSelectors.values(form)
displayValues = FormSelectors.displayValues(form)
options = FormSelectors.options(form)

consentMeta = [
  ConsentFields.Phone,
  ConsentFields.Email,
  ConsentFields.PaypalUnder18,
  ConsentFields.Demo,
  ConsentFields.Terms
]

coreFieldNames = _.values(
  _.omit(Fields, [Fields.UNDER_18_TERMS]) # We don't send UNDER_18_TERMS back to the server
)
demographicFieldNames = _.values(DemographicFields)
consentFieldNames = _.map(consentMeta, 'name')
domain = createSelector(
  values,
  alreadyConsentedTo
  (values, alreadyConsentedTo) ->
    _.assign(
      _.pick(values, coreFieldNames),
      {
        demographics: _.pick(values, demographicFieldNames)
      },
      {
        consent: {
          consentingToMap: _.pick(values, consentFieldNames),
          alreadyConsentedTo
        }
      }
    )
)

userUnder18 = createSelector(
  values,
  (values) ->
    if values.day? && values.month? && values.year?
      birthDate = moment([values.year, values.month, values.day])
      age = moment.duration(moment().diff(birthDate)).asYears()
      age < 18
    else false
)

userEmail = createSelector(
  displayValues,
  (values) -> values.email
)

parentEmail = createSelector(
  profile,
  (profile) -> profile.parentEmail
)

module.exports = {
  profile,
  alreadyConsentedTo,
  mrp,
  meta,
  form,
  userUnder18,
  domain,
  options,
  displayValues,
  consentMeta,
  userEmail,
  parentEmail,
  childInfos,
  genders
}
