Form            = require 'common/forms/form.reducers'
Fields          = require './fields'
ConsentFields   = require '../../components/consent/consent.fields'
Consent         = require '../../components/consent/consent.reducers'
Namespace       = require './namespace'

module.exports = new Form.Builder()
  .withNamespace(Namespace)
  .withField(Fields.FIRST_NAME, Form.InputField())
  .withField(Fields.LAST_NAME, Form.InputField())
  .withField(Fields.MOBILE, Form.InputField())
  .withField(Fields.EMAIL, Form.InputField())
  .withField(Fields.PASSWORD, Form.InputField())
  .withField(ConsentFields.Email.name, Consent.Email())
  .withField(ConsentFields.Phone.name, Consent.Phone())
  .withField(ConsentFields.Terms.name, Consent.Terms())
  .build()
