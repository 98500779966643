injected            = require 'common/redux/injected'
LoadingActions      = require 'common/layout/loading.action.creators'
RouterActions       = require 'common/layout/router.action.creators'
LayoutSelectors     = require 'common/layout/selectors'
FormActionsCreators = require 'common/forms/form.action.creators'
ConsentFields       = require '../components/consent/consent.fields'
Actions             = require './parental-consent.actions'
Selectors           = require './parental-consent.selectors'
Namespace           = require './parental-consent.namespace'

FormActions = FormActionsCreators(Namespace)

module.exports =
  consentChanged: (field, value) ->
    (dispatch) ->
      action = FormActions.fieldChanged(field)(value)
      action.payload.userUnder18 = false

      dispatch(action)
      
  initialLoad: (hash) ->
    (dispatch, getState, extraArgs) ->
      LoadingActions.loadingData( ->
        injected(extraArgs, 'ParentalConsentService').consentMetaData(hash)
          .then((parentalConsentMeta) ->
            dispatch(
              type: Actions.INITIAL_LOAD
              payload: {
                parentalConsentMeta
              }
            )

            for field in parentalConsentMeta.consentsGiven
              dispatch(FormActions.initfield(field)({ value: true }))
          )
      )(dispatch, getState, extraArgs)

  submit: ->
    (dispatch, getState, extraArgs) ->
      state = getState()
      {hash} = LayoutSelectors.currentParams(state)
      form = Selectors.form(state)

      if form.isValid
        dispatch(FormActions.submittingForm())

        LoadingActions.loadingData( ->
          injected(extraArgs, 'ParentalConsentService').submitConsentForm(hash, Selectors.domainModel(state))
            .then((message) ->
              dispatch(RouterActions.stateGo('oneq.member.parental-consent-success', { message }))
            )
        )(dispatch, getState, extraArgs)
      else
        console.log("Not a valid form")
