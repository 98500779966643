ProfileService = (OneQClientHandler, OneQClient) ->
  baseUrl = "/web/api/user/member/v1"

  getProfile: ->
    OneQClientHandler.get("#{baseUrl}/profile")
      .then((response) -> response.data)

  updateProfile: (data) ->
    OneQClientHandler.put("#{baseUrl}/profile", data)
      .then((response) -> response.data)

  confirmVerifcationCode: (verificationCode) ->
    OneQClientHandler.put("#{baseUrl}/verify",
      verificationCode: verificationCode
    )

  deactivate: ->
    OneQClientHandler.put("#{baseUrl}/me/deactivate")

ProfileService.$inject = ['OneQClientHandler', 'OneQClient']

module.exports = ProfileService
