Actions = require './session.action.creators'

initialSession = ($ngRedux) ->
  $ngRedux.dispatch(Actions.updateSession())

initialSession.$inject = ['$ngRedux']

RouterConfig = ($locationProvider, $urlRouterProvider, $stateProvider) ->
  $locationProvider.html5Mode(true)
  $urlRouterProvider.otherwise('/')

  $stateProvider
    .state(
      name: 'oneq',
      component: 'layout'
      abstract: true
      onEnter: initialSession
    )

RouterConfig.$inject = ['$locationProvider', '$urlRouterProvider', '$stateProvider']

module.exports = RouterConfig
