_                   = require 'lodash'
{ createSelector }  = require 'reselect'
FormSelectors       = require 'common/forms/form.selectors'
ConsentFields       = require '../../components/consent/consent.fields'
Fields              = require './fields'

register = (state) -> state.member.register

form = createSelector(
  register,
  (register) -> register.form
)
values = FormSelectors.values(form)
options = FormSelectors.options(form)
displayValues = FormSelectors.displayValues(form)
consentMeta = [
  ConsentFields.Phone,
  ConsentFields.Email,
  ConsentFields.Terms
]

coreFieldNames = _.values(Fields)
consentFieldNames = _.map(consentMeta, 'name')
domainModel = createSelector(
  values,
  (values) ->
    _.assign(
      _.pick(values, _.values(Fields)),
      {
        consent: {
          consentingToMap: _.pick(values, consentFieldNames),
          alreadyConsentedTo: []
        }
      }
    )
)

module.exports = {
  form,
  options,
  displayValues,
  consentMeta
  domainModel
}
