angular = require 'angular'

VendorModule = require 'common/vendor.module'

require 'common/oneq-client-service'
require 'common/oneq-client-with-error-service'
require 'common/forms/forms-module'
require 'common/forms/check-required'
require 'common/forms/oneq-alerts'
require 'common/forms/input.directives'
require 'common/loading-directive'

InjectedThunk = require 'common/redux/injected-thunk'

Facebook = require './services/facebook.service'
ReferralService = require './services/referral.service'
MemberConsentService = require './services/consent.service'

LayoutModule = require 'common/layout/layout.module'
SecurityModule = require 'common/security/security.module'
UtilModule = require 'common/util/util.module'
RegisterModule = require './register/register.module'
ParentalConsentModule = require './parental-consent/parental-consent.module'
ProfileModule = require './profile/profile.module'
ConfirmationModule = require './confirmation/confirmation.module'

RouterConfig = require './member.router'
ReducerConfig = require './member.reducer'

module.exports = angular.module('oneq.member', [
  VendorModule,
  'oneq.forms',
  'oneq.client.error.handling',
  LayoutModule,
  UtilModule,
  SecurityModule,
  RegisterModule,
  ParentalConsentModule,
  ProfileModule,
  ConfirmationModule
]).config(ReducerConfig)
  .config(RouterConfig)
  .factory('FacebookEndpoints', Facebook.Endpoints)
  .factory('FacebookService', Facebook.Service)
  .factory('ReferralService', ReferralService)
  .factory('MemberConsentService', MemberConsentService)
  .factory('InjectedThunk', InjectedThunk)
  .run(['loadScript', (loadScript) ->
    loadScript("twitter-wjs", "https://platform.twitter.com/widgets.js")
  ])
  .name
