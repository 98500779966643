Actions = require './action.creators'

initialLoad = ($ngRedux) ->
  $ngRedux.dispatch(Actions.initialLoad())

initialLoad.$inject = ['$ngRedux']

RouterConfig = ($stateProvider) ->
  $stateProvider
    .state(
      name: 'oneq.member.confirmation'
      url: '/members/confirmation'
      resolve: { initialLoad }
      views:
        'alert-banner@oneq':
          component: 'confirmationReferralBanner'
        'content@oneq':
          component: 'confirmation'
    )

RouterConfig.$inject = ['$stateProvider']

module.exports = RouterConfig
