angular = require 'angular'

require 'common/user/demographics/demographic-module'
require 'common/user/demographics/demographics-service'

RegisterService = require './register.service'
FormModule = require './form/register-form.module'
DownloadModule = require './download/download.module'
RouterConfig = require './register.router'
MemberConsentModule = require '../components/consent/consent.module'
ScrollToTopTransition = require 'common/animations/scroll-to-top'

module.exports = angular.module('oneq.member.register', [
  'user.demographics',
  FormModule,
  DownloadModule,
  MemberConsentModule
]).config(RouterConfig)
  .factory('RegisterService', RegisterService)
  .component('registerMarketing', require('./marketing.component'))
  .component('registerPage', require('./register.component'))
  .run(ScrollToTopTransition.scrollToTopTransition)
  .name
