_             = require 'lodash'
Actions       = require '../loading.actions'

done =
  queue: []
  active: false
  message: null

module.exports = (state=done, action) ->
  switch action.type
    when Actions.DATA_LOADING
      queue: [state.queue..., action.payload]
      active: true
      message: action.payload.message

    when Actions.DATA_LOADED
      {id} = action.payload
      queue = _.reject(state.queue, ['id', id])
      active = !_.isEmpty(queue)
      message = if active then _.last(queue).message else null

      { queue, active, message }

    else state
