_                   = require 'lodash'
{ createSelector }  = require 'reselect'
FormSelectors       = require 'common/forms/form.selectors'
ConsentFields       = require '../components/consent/consent.fields'

parentalConsent = (state) -> state.member.parentalConsent
meta = createSelector(
  parentalConsent,
  (parentalConsent) -> parentalConsent.meta
)

form = createSelector(
  parentalConsent,
  (parentalConsent) -> parentalConsent.form
)

values = FormSelectors.values(form)
displayValues = FormSelectors.displayValues(form)

domainModel = createSelector(
  values,
  (values) -> _.keys(_.pickBy(values, _.identity))
)

childName = createSelector(
  meta,
  (meta) -> meta.childName
)

childEmail = createSelector(
  meta,
  (meta) -> meta.childEmail
)

consentsGiven = createSelector(
  meta,
  (meta) -> meta.consentsGiven
)

module.exports = {
  parentalConsent,
  form,
  displayValues,
  domainModel,
  childName,
  childEmail,
  consentsGiven
}
