RegisterService = (OneQClientHandler) ->
  baseUrl = "/web/api/user/member/v1"

  sendDownloadLink: (formData) ->
    OneQClientHandler.post("#{baseUrl}/send-download-link", formData).then((response) -> response.data)

  register: (formData) ->
    OneQClientHandler.post(baseUrl, formData).then((response) -> response.data)

RegisterService.$inject = ['OneQClientHandler']

module.exports = RegisterService
