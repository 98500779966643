Actions = require './action.creators'

redirectRegistration = ($ngRedux) ->
  $ngRedux.dispatch(Actions.redirectRegistration())

redirectRegistration.$inject = ['$ngRedux']

peopleAvailable = ($ngRedux) ->
  $ngRedux.dispatch(Actions.peopleAvailable())

peopleAvailable.$inject = ['$ngRedux']

RouterConfig = ($stateProvider) ->
  $stateProvider
    .state(
      name: 'oneq.member.register'
      abstract: true
      resolve:
        peopleAvailable: peopleAvailable
      views:
        'content@oneq':
          component: 'registerPage'
      onEnter: redirectRegistration
    )

RouterConfig.$inject = ['$stateProvider']

module.exports = RouterConfig
