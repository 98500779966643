_                   = require 'lodash'
cuid                = require 'cuid'
injected            = require 'common/redux/injected'
LoadingActions      = require 'common/layout/loading.action.creators'
SessionActions      = require 'common/layout/session.action.creators'
RouterActions       = require 'common/layout/router.action.creators'
FormActionCreators  = require 'common/forms/form.action.creators'
FieldConverters     = require 'common/forms/field-converters'
ErrorMessage        = require 'common/util/error-message'
Actions             = require './actions'
Selectors           = require './selectors'
Fields              = require './fields'
DemographicFields   = require './demographic.fields'
Namespace           = require './namespace'
ChildInfoActionCreators  = require './child.info.action.creators'

FormActions = FormActionCreators(Namespace)

INPUT_FIELDS = [
  Fields.FIRST_NAME,
  Fields.LAST_NAME,
  Fields.EMAIL,
  Fields.PHONE,
  Fields.POSTAL_CODE,
  Fields.STREET_ADDRESS
]

module.exports =
  consentChanged: (field, value) ->
    (dispatch, getState) ->
      action = FormActions.fieldChanged(field)(value)
      action.payload.userUnder18 = Selectors.userUnder18(getState())

      dispatch(action)
  charityChanged: FormActions.fieldChanged(Fields.CHARITY)
  firstNameChanged: FormActions.fieldChanged(Fields.FIRST_NAME)
  lastNameChanged: FormActions.fieldChanged(Fields.LAST_NAME)
  emailChanged: FormActions.fieldChanged(Fields.EMAIL)
  countryChanged: FormActions.fieldChanged(Fields.COUNTRY)
  phoneChanged: FormActions.fieldChanged(Fields.PHONE)
  birthDayChanged: FormActions.fieldChanged(Fields.BIRTH_DAY, FieldConverters.toInt())
  birthMonthChanged: FormActions.fieldChanged(Fields.BIRTH_MONTH, FieldConverters.toInt())
  birthYearChanged: FormActions.fieldChanged(Fields.BIRTH_YEAR, FieldConverters.toInt())
  streetAddressChanged: FormActions.fieldChanged(Fields.STREET_ADDRESS)
  postalCodeChanged: FormActions.fieldChanged(Fields.POSTAL_CODE)
  memberPaymentChanged: FormActions.fieldChanged(Fields.MEMBER_PAYMENT, FieldConverters.toFloat())
  genderChanged: FormActions.fieldChanged(DemographicFields.GENDER)
  maritalStatusChanged: FormActions.fieldChanged(DemographicFields.MARITAL_STATUS)
  raceChanged: FormActions.fieldChanged(DemographicFields.RACE)
  educationLevelChanged: FormActions.fieldChanged(DemographicFields.EDUCATION_LEVEL)
  employmentStatusChanged: FormActions.fieldChanged(DemographicFields.EMPLOYMENT_STATUS)
  industryChanged: FormActions.fieldChanged(DemographicFields.INDUSTRY)
  jobFunctionChanged: FormActions.fieldChanged(DemographicFields.JOB_FUNCTION)
  jobLevelChanged: FormActions.fieldChanged(DemographicFields.JOB_LEVEL)
  householdIncomeChanged: FormActions.fieldChanged(DemographicFields.HOUSEHOLD_INCOME)
  politicalAffiliationChanged: FormActions.fieldChanged(DemographicFields.POLITICAL_AFFILIATION)
  religionChanged: FormActions.fieldChanged(DemographicFields.RELIGION)
  homeOwnershipStatusChanged: FormActions.fieldChanged(DemographicFields.HOME_OWNERSHIP_STATUS)
  voterRegistrationChanged: FormActions.fieldChanged(DemographicFields.VOTER_REGISTRATION)
  numberOfChildrenChanged: FormActions.fieldChanged(DemographicFields.NUMBER_OF_CHILDREN)

  initialLoad: ->
    (dispatch, getState, extraArgs) ->
      [$q, ProfileService, ReferralService] = injected(extraArgs, [
        '$q',
        'ProfileService',
        'ReferralService'
      ])

      dispatch(
        LoadingActions.loadingData( ->
          $q.all(
            profileData: ProfileService.getProfile()
            referralData: ReferralService.getStatus()
          ).then(({ profileData: { options, profile }, referralData }) ->
            { consent: { alreadyConsentedTo }, demographics } = profile

            dispatch(
              type: Actions.INITIAL_LOAD
              payload: {
                meta: options,
                mrp: referralData,
                alreadyConsentedTo
              }
            )

            # TODO: All suggestions welcome for an explicit, but less-fugly way of initializing the fields with previously-saved data
            for field, value of profile
              if field in INPUT_FIELDS
                dispatch(FormActions.initField(field)({ value }))

            dispatch(ChildInfoActionCreators.childInfoLoaded(profile?.childInfos))

            dispatch(FormActions.initField(Fields.CHARITY)(
              value: profile[Fields.CHARITY] || ''
              options: options.charities
            ))

            dispatch(FormActions.initField(Fields.COUNTRY)(
              value: profile[Fields.COUNTRY]
              options: options.countries
            ))

            dispatch(FormActions.initField(Fields.BIRTH_DAY)(
              value: profile[Fields.BIRTH_DAY]
              options: options.days
            ))

            dispatch(FormActions.initField(Fields.BIRTH_MONTH)(
              value: profile[Fields.BIRTH_MONTH]
              options: options.months
            ))

            dispatch(FormActions.initField(Fields.BIRTH_YEAR)(
              value: profile[Fields.BIRTH_YEAR]
              options: options.years
            ))

            dispatch(FormActions.initField(Fields.MEMBER_PAYMENT)(
              value: profile[Fields.MEMBER_PAYMENT]
              options: options.paymentOptions
            ))

            for key, name of DemographicFields
              dispatch(FormActions.initField(name)(
                value: demographics[name]
                options: options.demographics[name]
              ))

            for field in alreadyConsentedTo
              dispatch(FormActions.initField(field)({ value: true }))
          )
        )
      )

  submit: ->
    (dispatch, getState, extraArgs) ->
      loadingId = cuid()

      [ProfileService, VerificationCodeLauncher, ParentalEmailLauncher] = injected(extraArgs, [
        'ProfileService',
        'VerificationCodeLauncher',
        'ParentalEmailLauncher'
      ])

      dispatch(FormActions.submittingForm())

      state = getState()
      form = Selectors.form(state)

      if form.isValid
        dispatch(LoadingActions.loadingStarted(loadingId))
        model = Selectors.domain(getState())

        ProfileService.updateProfile(model)
          .then(({ verifyCode, requireParentalConsent }) ->
            dispatch(LoadingActions.loadingComplete(loadingId))

            VerificationCodeLauncher.launch(verifyCode)
              .then((verified) ->
                if verified
                  if requireParentalConsent
                    ParentalEmailLauncher.launch()
                  else
                    dispatch(RouterActions.stateGo('oneq.member.confirmation'))
              )
          )
          .finally( -> dispatch(LoadingActions.loadingComplete(loadingId)))

  deactivate: ->
    (dispatch, getState, extraArgs) ->

      [$uibModal, $window] = injected(extraArgs, '$uibModal', '$window')

      $uibModal.open({ component: 'profileDeactivate', size: 'md' }).result
        .then((deactivated) ->
          $window.location.href = '/' if deactivated
        )

  parentEmailOpening: ->
    type: Actions.PARENT_EMAIL_OPENING
    payload: {}

  updateParentEmail: (value) ->
    type: Actions.UPDATE_PARENT_EMAIL
    payload: value

  sendParentEmail: ->
    (dispatch, getState, extraArgs) ->
      ParentalConsentService = injected(extraArgs, 'ParentalConsentService')

      state = getState()
      userEmail = Selectors.userEmail(state)
      parentEmail = Selectors.parentEmail(state).parentEmail

      dispatch(
        type: Actions.PARENT_EMAIL_SENDING
        payload: {}
      )

      ParentalConsentService.sendParentEmailAddress(parentEmail, userEmail)
        .then(
          (success) ->
            dispatch(
              type: Actions.PARENT_EMAIL_SUCCESS
              payload: success
            )
          ,
          (response) ->
            errorMessage =
              if response.status == 400
                ErrorMessage.forResponse(response)
              else 'Unexpected Error'

            type: Actions.PARENT_EMAIL_FAILURE
            payload: errorMessage
        )
