_               = require 'lodash'
SessionActions  = require './session.action.creators'
Selectors       = require './selectors'

class LayoutController
  constructor: (@$ngRedux, @$window, @HeaderNavFactory) ->

  $onInit: ->
    @$ngRedux.connect(@mapState, SessionActions)(@)

  mapState: (state) =>
    loadingStatus: Selectors.loadingStatus(state)
    location: @HeaderNavFactory.buildLocation()
    session:
      user: Selectors.user(state)
      isLoggedIn: () => Selectors.isLoggedIn(state)
      isLoggedOut: () =>  Selectors.isLoggedOut(state)
      isLoggedInAsker: () => Selectors.isLoggedInAsker(state)

LayoutController.$inject = ['$ngRedux', '$window', 'HeaderNavFactory']

module.exports =
  LayoutComponent:
    controller: LayoutController
    template: require('./layout.template.html')
  LayoutController: LayoutController
