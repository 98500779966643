Actions = require './action.creators'

initialLoad = ($ngRedux) ->
  $ngRedux.dispatch(Actions.initialLoad())

initialLoad.$inject = ['$ngRedux']

RouterConfig = ($stateProvider) ->
  $stateProvider
    .state(
      name: 'oneq.member.register.download'
      url: '/members/download'
      resolve:
        initialLoad: initialLoad
      views:
        '@oneq.member.register':
          component: 'downloadForm'
    )

RouterConfig.$inject = ['$stateProvider']

module.exports = RouterConfig
