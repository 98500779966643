Actions = require './actions'

ActionCreators =
  childAgeMonthChanged: ($index, month) ->
    type: Actions.CHILD_AGE_MONTH_CHANGED
    payload: {atIndex: $index, month}
  childAgeYearChanged: ($index, year) ->
    type: Actions.CHILD_AGE_YEAR_CHANGED
    payload: {atIndex: $index, year}
  childGenderChanged: ($index, gender) ->
    type: Actions.CHILD_GENDER_CHANGED
    payload: {atIndex: $index, gender}
  childInfoAdded: ($index) ->
    type: Actions.CHILD_INFO_ADDED
    payload: {atIndex: $index}
  childInfoRemoved: ($index) ->
    type: Actions.CHILD_INFO_REMOVED
    payload: {atIndex: $index}
  childInfoLoaded: (childInfos) ->
    type: Actions.CHILD_INFO_LOADED
    payload: {childInfos}


module.exports = ActionCreators
