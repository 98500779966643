defaultColor = "#FFA500"

darkenColor = (col, amt) ->
  usePound = false
  if col[0] == '#'
    col = col.slice(1)
    usePound = true
  num = parseInt(col, 16)
  r = (num >> 16) + amt
  if r > 255
    r = 255
  else if r < 0
    r = 0
  b = (num >> 8 & 0x00FF) + amt
  if b > 255
    b = 255
  else if b < 0
    b = 0
  g = (num & 0x0000FF) + amt
  if g > 255
    g = 255
  else if g < 0
    g = 0
  (if usePound then '#' else '') + (g | b << 8 | r << 16).toString(16)

numberWithCommas = (number) ->
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

buildFillAttrs = (stateCountsList) ->
  regions = {}
  maxCount = 0
  for valueCount in stateCountsList
    maxCount = valueCount.count if valueCount.count > maxCount

  for valueCount in stateCountsList
    #Determine percentage difference from the largest state and invert it. Multiply by 5 to help distinguish differences better
    percentage = Math.max((1 - ((maxCount - valueCount.count) / maxCount)) * 5, 0.25)
    #For the states that have a high population, and were over-corrected with the scalar of 5, darken them
    fill = if percentage > 1.0 then darkenColor(defaultColor, percentage * -10) else defaultColor
    regions[valueCount.value] = {
      tooltip: 'Population for ' + valueCount.value + ': ' + numberWithCommas(valueCount.count)
      attr:
        fill: fill
        opacity: percentage
        cursor: 'pointer'
    }
  regions

DemographicMapDirective = ->
  restrict: 'E'
  transclude: true
  scope:
    demographics: '='
  link: ($scope, element, attrs) ->
    $scope.showMap = false
    $scope.$watch('demographics', (demographics) ->
      if demographics?
        regions = buildFillAttrs(demographics.dataMapFor('state'))

        $(element).find('div#map_canvas_' + $scope.$id).mapSvg(
          source: '/images/maps/usa.svg'
          responsive: true
          height: 450
          colors:
            background: 'transparent'
            hover: '#e18309'
            selected: '#e18309'
            stroke: 'white'
          tooltipsMode: 'custom'
          regions: regions
        )

        $scope.showMap = true
    )

  template: '''
    <div class="map real-data">
        <div id="map_canvas_{{::$id}}" class="map-canvas" ng-show="showMap"></div>
        <div class="map-loading" ng-hide="showMap">
            <div class="loading-gif fa fa-refresh fa-spin fa-3x"></div>
        </div>
        <div ng-transclude ng-show="showMap"></div>
    </div><!-- map -->
  '''

module.exports = DemographicMapDirective
