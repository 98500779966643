# Import legacy vendor scripts in the correct order
angular = window.angular = require 'angular' #'exports-loader?window.angular!angular'

require 'common/oneq-client-service'

SecurityService = require './security.service'
{ShowIfLoggedIn, ShowIfLoggedOut, ShowIfLoggedInAsker, ShowIfNotLoggedInAsker, ShowIfLoggedOutAndBusiness, ShowIfLoggedOutAndNotBusiness, ShowIfLoggedOutAndNotLoginOrSignUpPage, ShowIfLoggedOutAndNotProfile} = require './security.directives'

module.exports = angular.module('oneq.client.security', ['oneq.client'])
  .factory('SecurityService', SecurityService)
  .directive('showIfLoggedIn', ShowIfLoggedIn)
  .directive('showIfLoggedOut', ShowIfLoggedOut)
  .directive('showIfLoggedInAsker', ShowIfLoggedInAsker)
  .directive('showIfNotLoggedInAsker', ShowIfNotLoggedInAsker)
  .directive('showIfLoggedOutAndBusiness', ShowIfLoggedOutAndBusiness)
  .directive('showIfLoggedOutAndNotBusiness', ShowIfLoggedOutAndNotBusiness)
  .directive('showIfLoggedOutAndNotLoginOrSignUpPage', ShowIfLoggedOutAndNotLoginOrSignUpPage)
  .directive('showIfLoggedOutAndNotProfile', ShowIfLoggedOutAndNotProfile)
  .name
