( ->

  OneQClientHandler = (OneQClient, OneQAlerts, $q) ->
    client = {}

    wrapWithErrorHandling = (promise, passThruError) ->
      if passThruError
        promise
      else
        promise.catch((error) ->
          defaultError = error?.data?.message || error?.data

          fieldErrors = _.flatten(_.values(error?.data?.fieldErrors || {}))
          globalErrors = error?.data?.globalErrors || []

          errors = _.map(globalErrors.concat(fieldErrors), 'message')
          if errors.length < 1 and defaultError?.length > 0
            if Array.isArray(defaultError)
              errors = defaultError
            else
              errors = [defaultError]
          otherErrors = error?.data?.errors || []

          headerErrors = error?.headers?('X-Blob-Error') || []

          OneQAlerts.error(errors.concat(otherErrors).concat(headerErrors), { title: 'Error Occurred' })
            .result.then(angular.noop).catch(angular.noop)
          $q.reject(error)
        )

    for method in ['head', 'get', 'delete', 'jsonp']
      do (method) ->
        client[method] = (url, config) ->
          wrapWithErrorHandling(OneQClient[method](url, config), config?.passThruError)

    for method in ['put', 'post', 'patch']
      do (method) ->
        client[method] = (url, data, config) ->
          wrapWithErrorHandling(OneQClient[method](url, data, config), config?.passThruError)

    client

  OneQClientHandler.$inject = ["OneQClient", "OneQAlerts", "$q"]

  angular.module("oneq.client.error.handling",['oneq.client'])
    .factory("OneQClientHandler", OneQClientHandler)

)()
