_ = require 'lodash'

module.exports = (mapState, actions = {}) ->
  # Dumbed-down version of $ngRedux.connect(...), due to apparent bugs in the $ngRedux version
  # TODO: Find a minimally complex version of that test case that causes the issue and submit a bug report or a PR
  class ComponentController
    constructor: (@$ngRedux) ->
      _.forEach(actions, (value, key) =>
        @[key] = => @$ngRedux.dispatch(value(arguments...))
      )

    $onInit: ->
      state = @$ngRedux.getState()

      @unsubscribe = @$ngRedux.subscribe(=>
        state = @$ngRedux.getState()
        slice = mapState(state)
        _.assign(@, slice)
      )

    $onDestroy: ->
      @unsubscribe()

  ComponentController.$inject = ['$ngRedux']

  ComponentController
