
angular = require 'angular'

SessionTrackingFactory = ($window) ->

  Namespace = "x-session-tracking"

  namespaced = (key) ->
    "#{Namespace}-#{key}"

  JwtTrackingId = namespaced("x-jwt-tracking-id")

  TrackingFields = [
    JwtTrackingId
  ]

  getTrackingFields = () ->
    TrackingFields.map( (fieldName) ->
      "#{fieldName}": $window.localStorage?.getItem(fieldName)
    )

  setTrackingField = (fieldName, fieldValue) ->
    $window.localStorage?.setItem(fieldName, fieldValue) if fieldValue?

  removeTrackingField = (fieldName) ->
    $window.localStorage?.removeItem(fieldName)

  removeAllTrackingFields = () ->
    for trackingField in TrackingFields
      removeTrackingField(trackingField)

  parseHeaderTrackingFields = (response) ->
    for trackingFieldName in TrackingFields 
      value = response.headers(trackingFieldName)
      if value is ""
        removeTrackingField(trackingFieldName)
      else if value?
        setTrackingField(trackingFieldName, value)

  JwtTrackingId: JwtTrackingId

  getTrackingFields: getTrackingFields
  removeAllTrackingFields: removeAllTrackingFields
  parseHeaderTrackingFields: parseHeaderTrackingFields
  
SessionTrackingFactory.$inject = ["$window"]

module.exports = 
  angular.module('oneq.client.session.tracking', [])
        .factory("SessionTrackingFactory", SessionTrackingFactory)
        .name