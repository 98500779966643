_ = require 'lodash'

module.exports =
  toInt: (pattern=/^\d+$/) -> (value) ->
    if typeof value == 'string' && value.match(pattern)
      parseInt(value)
    else
      value

  toFloat: (pattern=/^\d+(\.\d+)?$/) -> (value) ->
    if typeof value == 'string' && value.match(pattern)
      parseFloat(value)
    else
      value
