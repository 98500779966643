ConfirmationService = (OneQClient) ->
  baseUrl = "/web/api/user/member/v1"

  resendDownloadLink: ->
    OneQClient.post("#{baseUrl}/me/resend-download-link")

  confirmation: ->
    OneQClient.get("#{baseUrl}/me/confirmation")
      .then((response) -> response.data)

  selectMinimumPayment: ->
    OneQClient.put("#{baseUrl}/me/select-minimum-payment")

ConfirmationService.$inject = ['OneQClient']

module.exports = ConfirmationService
