_             = require 'lodash'
Actions       = require './actions'

module.exports = (state={}, action) ->
  switch action.type
    when Actions.INITIAL_LOAD
      _.assign({}, state, action.payload.confirmation, {
        referral: action.payload.referral
      })

    when Actions.MINIMUM_PAYMENT_SELECTED
      _.assign({}, state, {
        minimumPaymentSelected: action.payload
      })

    when Actions.REGISTERED_WITH_FACEBOOK
      _.assign({}, state, {
        registeredWithFacebook: action.payload
      })

    when Actions.DOWNLOAD_LINK_SENT
      _.omit(
        _.assign({}, state, { downloadLinkSent: action.payload }),
        ['downloadErrorMessage']
      )

    when Actions.DOWNLOAD_LINK_ERROR
      _.assign({}, state, {
        downloadErrorMessage: action.payload
      })

    else state
