( ->
  angular.module('oneq.client').directive("oneqLoadingModule", ->
    restrict: 'AE',
    scope:
      loadingData: '='
      loadingMessage: '@'

    template: '''
      <div id="loading-bg" class="modal-backdrop" ng-show="loadingData"></div>
      <div id="loading" ng-show="loadingData">
          <div id="loading-gif" class="fa fa-refresh fa-spin fa-3x"></div>
          <div id="loading-status">{{loadingMessage}}</div>
      </div>
    '''
  )
)()
