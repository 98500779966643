HeaderNavFactory = (@$window) ->
  factory = {}

  factory.indexLocation =  '/index'
  factory.homeLocation = ''
  factory.loginLocation = '/login'

  factory.signUpLocation = '/sign-up'
  factory.knowLocation = '/know'

  factory.profileLocation = '/members/profile'
  factory.registerLocation = '/members/register'
  factory.confirmationLocation = '/members/confirmation'
  factory.memberLocation = '/member'

  factory.homeLocations = [
    factory.indexLocation
    factory.homeLocation
  ]
  factory.whiteLocations = [
    factory.memberLocation
    factory.registerLocation
    factory.profileLocation
    factory.confirmationLocation
    factory.signUpLocation
  ]
  factory.memberLocations = [
    factory.memberLocation
    factory.registerLocation
    factory.profileLocation
    factory.confirmationLocation
  ]
  factory.businessLocations = [
    factory.knowLocation
    factory.signUpLocation
  ]
  factory.signUpLocations = [
    factory.signUpLocation
    factory.registerLocation
  ]

  factory.atPath =  (target) =>
    _.trimEnd(@$window.location.pathname, '/') == target

  factory.inPaths = (targets) =>
    _.findIndex(targets, (t) => factory.atPath(t) ) > -1

  factory.buildLogo = () =>
    whiteLogo: factory.inPaths(factory.whiteLocations)
    purpleLogo: false

  factory.buildLocation = () =>
    isLoginPage: factory.atPath(factory.loginLocation)
    isSignUpPage: => factory.inPaths(factory.signUpLocations)
    isProfilePage: => factory.atPath(factory.profileLocation)
    isHomePage: => factory.inPaths(factory.homeLocations)
    isBusinessPage:  => factory.inPaths(factory.businessLocations)
    isMemberPage: => factory.inPaths(factory.memberLocations)
    logo: factory.buildLogo()

  factory

HeaderNavFactory.$inject = ["$window"]

module.exports = HeaderNavFactory
