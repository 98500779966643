_                   = require 'lodash'
{ combineReducers } = require 'redux'

## Middleware
devMode = process.env.NODE_ENV isnt 'production'

Middleware = ['ngUiRouterMiddleware', 'InjectedThunk']
if (process.env.NODE_ENV isnt 'production')
  Logger = require 'redux-logger'
  Middleware = [Middleware..., Logger.default]

DevTools = DevTools =  _.get(window, '__REDUX_DEVTOOLS_EXTENSION__')
Enhancers = if DevTools then [ DevTools() ] else []

module.exports = {
  Middleware,
  Enhancers
}
