
angular = window.angular = require 'angular' #'exports-loader?window.angular!angular'

JwtSecurityFactory = () ->
  JwtExpiresAtKey = "x-jwt-eat"
  EXPIRED_EAT = 0
  BUFFER_TIME = 5 * 1000 #5 seconds

  getExpiresAt = () =>
    parseInt(localStorage?.getItem(JwtExpiresAtKey) || "0", 10)

  millisUntilExpiration = () =>
    getExpiresAt() - new Date().getTime()
    
  millisUntilExpirationWithBuffer = () =>
    millisUntilExpiration() - BUFFER_TIME

  setExpiresAt = (expires_at) =>
    if (expires_at <= EXPIRED_EAT)
      console.log("Removing JWT expires at")
      removeExpiresAt()
    else
      console.log("Updating JWT expires at to #{expires_at}")
      localStorage?.setItem(JwtExpiresAtKey, expires_at)

  removeExpiresAt = () =>
    localStorage?.removeItem(JwtExpiresAtKey)

  parseHeader = (response) =>
    response.headers(JwtExpiresAtKey)

  parseHeader: parseHeader
  millisUntilExpiration: millisUntilExpiration
  millisUntilExpirationWithBuffer: millisUntilExpirationWithBuffer
  getExpiresAt: getExpiresAt
  setExpiresAt: setExpiresAt
  removeExpiresAt: removeExpiresAt

JwtSecurityFactory.$inject = []

module.exports = 
  angular.module('oneq.client.security.jwt', [])
        .factory("JwtSecurityFactory", JwtSecurityFactory)
        .name