_                 = require 'lodash'
ActionCreators    = require './action.creators'
ChildInfoActionCreators = require './child.info.action.creators'
Component         = require 'common/redux/component-function'
ConsentFields     = require '../components/consent/consent.fields'
Selectors         = require './selectors'

mapStateToThis = (state) ->
  _.assign(Selectors.displayValues(state),
    showUnder18Terms: Selectors.userUnder18(state)
    options: Selectors.options(state)
    form: Selectors.form(state)
    mrp: Selectors.mrp(state)
    meta: Selectors.meta(state)
    childInfos: Selectors.childInfos(state)
    genders: Selectors.genders(state)
    alreadyConsentedTo: Selectors.alreadyConsentedTo(state)
    consentMeta: Selectors.consentMeta
  )

ProfileController = Component(mapStateToThis, _.assign({}, ActionCreators, ChildInfoActionCreators))

module.exports =
  controller: ProfileController
  template: require('./profile.template.html')
