class ProfileDeactivateController
  constructor: (@ProfileService) ->

  confirm: ->
    @ProfileService.deactivate()
      .then( => @close({ $value: true }))

  cancel: ->
    @dismiss({ $value: false })

ProfileDeactivateController.$inject = ['ProfileService']

module.exports =
  bindings:
    resolve: '<'
    close: '&'
    dismiss: '&'
  controller: ProfileDeactivateController
  template: require('./profile-deactivate.template.html')
