{ createSelector } = require 'reselect'

router = (state) -> state.router
currentParams = createSelector(
  router,
  (router) -> router.currentParams
)
currentState = createSelector(
  router,
  (router) -> router.currentState
)
currentHash = createSelector(
  currentParams,
  (currentParams) -> currentParams.hash
)
user = (state) -> state.session.user
isAsker = (state) -> state.session.isAsker
isLoggedIn = (state) -> state.session.isLoggedIn()
isLoggedOut = createSelector(isLoggedIn, (loggedIn) -> !loggedIn)
isLoggedInAsker = createSelector(
  isLoggedIn,
  isAsker,
  (loggedIn, asker) -> loggedIn && asker
)
isProfilePage = createSelector(
  (state) -> state.router.currentState
  (currentState) -> currentState.name == 'oneq.member.profile'
)
loadingStatus = (state) -> state.loading

module.exports = {
  router,
  currentParams,
  currentState,
  currentHash,
  user,
  isAsker,
  isLoggedIn,
  isLoggedOut,
  isLoggedInAsker,
  isProfilePage,
  loadingStatus
}
