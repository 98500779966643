_                     = require 'lodash'
{ combineReducers }   = require 'redux'
FormActions           = require 'common/forms/form.actions'
Actions               = require '../actions'
form                  = require './form.reducers'
parentEmail           = require './parent-email.reducers'
childInfoReducer      = require './child.info.reducers'

meta = (state={}, action) ->
  if action.type == Actions.INITIAL_LOAD
    action.payload.meta
  else state

alreadyConsentedTo = (state=[], action) ->
  if action.type == Actions.INITIAL_LOAD
    action.payload.alreadyConsentedTo
  else state

mrp = (state={}, action) ->
  if action.type == Actions.INITIAL_LOAD
    action.payload.mrp || {}
  else state

childInfos = (state = [], action) ->
  if action.type in Actions.CHILD_INFO_ACTIONS.concat([FormActions.FORM_FIELD_CHANGED, FormActions.FORM_FIELD_INIT])
    childInfoReducer(state, action)
  else
    state

module.exports = combineReducers({
  meta,
  alreadyConsentedTo,
  mrp,
  form,
  parentEmail,
  childInfos
})
