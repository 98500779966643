class User
  constructor: (@firstName) ->

User.Factory = (data) ->
  new User(
    data.firstName
  )

class Company
  constructor: (@id, @name, @enabledFeatures) ->

Company.Factory = (data) ->
  new Company(data.id, data.name, data.enabledFeatures)

class Referrer
  constructor: (@companyId, @userId) ->

Referrer.Factory = (data) ->
  new Referrer(data.companyId, data.userId)

class Session
  constructor: (@user, @company, @referrer, @isAsker, @isActive) ->

  isLoggedIn: =>
    @user?

  isLoggedOut: =>
    !@isLoggedIn()

Session.Factory = (data) ->
  user = User.Factory(data.user) if data?.user?
  company = Company.Factory(data.company) if data?.company?
  referrer = Referrer.Factory(data.referrer) if data?.referrer?

  new Session(
    user,
    company,
    referrer,
    data.isAsker,
    data.isActive
  )

Session.Anonymous =
  new Session(
    null,
    null,
    null,
    false,
    false,
    false
  )

module.exports =
  User: User
  Company: Company
  Referrer: Referrer
  Session: Session
