_                         = require 'lodash'
{ combineReducers }       = require 'redux'
{ router }                = require 'redux-ui-router'
{ Middleware, Enhancers } = require 'common/redux/reducer.config'
{ session, loading }      = require 'common/layout/reducers'
register                  = require './register/reducers'
profile                   = require './profile/reducers'
confirmation              = require './confirmation/reducers'
parentalConsent           = require './parental-consent/parental-consent.reducers'

member = combineReducers({
  register,
  profile,
  confirmation,
  parentalConsent
})

rootReducer = combineReducers({
  session,
  loading,
  router,
  member
})

ReducerConfig = ($ngReduxProvider) ->
  $ngReduxProvider.createStoreWith(rootReducer, Middleware, Enhancers)

ReducerConfig.$inject = ['$ngReduxProvider']

module.exports = ReducerConfig
