_             = require 'lodash'
Form          = require 'common/forms/form.reducers'
FormActions   = require 'common/forms/form.actions'
Validators    = require 'common/forms/validators'
ConsentFields = require './consent.fields'

onlyRequiredUnder18 = (message) -> (value, action) ->
  if action?.payload?.userUnder18
    Validators.checked(message)(value, action)
  else
    []

module.exports =
  Email: -> Form.InputField(false, [Validators.checked(ConsentFields.Email.validationMessage)])
  Phone: -> Form.InputField(false, [Validators.checked(ConsentFields.Phone.validationMessage)])
  Demo: -> Form.InputField(false, [Validators.checked(ConsentFields.Demo.validationMessage)])
  Terms: -> Form.InputField(false, [Validators.checked(ConsentFields.Terms.validationMessage)])
  PaypalUnder18: -> Form.InputField(false, [onlyRequiredUnder18(ConsentFields.PaypalUnder18.validationMessage)])
