_         = require 'lodash'
cuid      = require 'cuid'
injected  = require 'common/redux/injected'
Actions   = require './loading.actions'

loadingStarted = (id, message = 'Loading...')->
  type: Actions.DATA_LOADING
  payload: { id, message }

loadingComplete = (id) ->
  type: Actions.DATA_LOADED
  payload: { id }

module.exports = {
  loadingStarted,
  loadingComplete,

  loadingData: (promiseFn, message = 'Loading...') ->
    (dispatch, getState, extraArgs) ->
      id = cuid()

      dispatch(loadingStarted(id))

      $q = injected(extraArgs, '$q')

      $q((resolve, reject) ->
        promiseFn()
          .then(resolve, reject)
          .finally(() ->
            dispatch(loadingComplete(id))
          )
      )
}
