class ChildInfoController
  constructor: ->
    currentYear = (new Date).getFullYear()
    endYear = currentYear - 110
    @years = [currentYear..endYear].map (item) -> item.toString()
    @months = [1..12].map (item) -> item.toString()

  $onInit: ->
    @childAge = @childInfo?.age
    @gender = @childInfo?.gender
    @showAddButton = @childInfoIndex >= 3
    @showRemoveButton = @childInfoIndex > 3

  monthChanged: ->
    @childAgeMonthChanged({$index: @childInfoIndex, month: @childAge.month})

  yearChanged: ->
    @childAgeYearChanged({$index: @childInfoIndex, year: @childAge.year})

  genderChanged: ->
    @childGenderChanged({$index: @childInfoIndex, gender: @gender})

  addChild: ->
    @childInfoAdded({$index: @childInfoIndex})

  removeChild: ->
    @childInfoRemoved({$index: @childInfoIndex})

module.exports =
    controller: ChildInfoController
    template: require './child.info.template.html'
    bindings:
        childInfo: '<'
        childInfoIndex: '<'
        genders: '<'
        childAgeMonthChanged: '&'
        childAgeYearChanged: '&'
        childGenderChanged: '&'
        childInfoRemoved: '&'
        childInfoAdded: '&'
