injected        = require 'common/redux/injected'
RouterActions   = require 'common/layout/router.action.creators'
LoadingActions  = require 'common/layout/loading.action.creators'
Actions         = require './actions'

module.exports =
  redirectRegistration: () ->
    (dispatch, getState, extraArgs) ->
      [$q, $window, CountryService] = injected(extraArgs, '$q', '$window', 'CountryService')

      $q((resolve, reject) ->
        CountryService.isAllowed()
          .then((countryAllowed) ->
            if !countryAllowed
              reject(false)
              $window.location.href = '/coming-soon'

            if getState().session.isLoggedIn()
              reject(false)
              dispatch(RouterActions.stateGo('oneq.member.profile'))
            else
              resolve(true)
          )
      )

  peopleAvailable: () ->
    (dispatch, getState, extraArgs) ->
      DemographicsService = injected(extraArgs, 'DemographicsService')

      dispatch(
        LoadingActions.loadingData( ->
          DemographicsService.peopleAvailable()
            .then(
              (peopleAvailable) ->
                dispatch(
                  type: Actions.PEOPLE_AVAILABLE
                  payload: peopleAvailable
                )
            )
        )
      )
