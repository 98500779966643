class AvatarMenuController
  $onInit: ->
    @isOpen = false

  $onChanges: (changes) ->
    @firstName = @session.user?.firstName
    @firstInitial = (@firstName?[0] || '?').toUpperCase()

  showDropdown: ->
    @isOpen = true

  hideDropdown: ->
    @isOpen = false

  toggleDropdown: ->
    @isOpen = !@isOpen

module.exports =
  bindings:
    location: '<'
    session: '<'
  controller: AvatarMenuController
  template: require('./avatar-menu.template.html')
