angular = require 'angular'

require 'map-svg'
# MapSVG.tinycolor = tinycolor

DemographicsService = require './demographics-service'
DemographicMapDirective = require './demographic-map-directive'
DemographicMapService = require './demographic-map-svg'

module.exports =
    angular.module("user.demographics",['oneq.client'])
        .factory('DemographicsService', DemographicsService)
        .directive('demographicMap', DemographicMapDirective)
        .service('DemographicMapService', DemographicMapService)
        .name
