_         = require 'lodash'
Component = require 'common/redux/component-function'
Actions   = require './action.creators'
Selectors = require './selectors'

ConfirmationController = Component(
  (state) ->
    progressStyle: Selectors.progressStyle(state)
    appDownloaded: Selectors.appDownloaded(state)
    minimumPaymentSelected: Selectors.minimumPaymentSelected(state)
    completeProfile: Selectors.completeProfile(state)
    earningPotential: Selectors.earningPotential(state)
    paymentsSentTo: Selectors.paymentsSentTo(state)
    registeredWithFacebook: Selectors.registeredWithFacebook(state)
    referral: Selectors.referral(state)
    downloadLinkSent: Selectors.downloadLinkSent(state)
    downloadErrorMessage: Selectors.downloadErrorMessage(state)
, Actions)

module.exports =
  controller: ConfirmationController
  template: require('./confirmation.template.html')
