Actions = require './action.creators'

RouterConfig = ($stateProvider) ->
  $stateProvider
    .state(
      name: 'oneq.member.register.form'
      url: '/members/register'
      views:
        '@oneq.member.register':
          component: 'registerForm'
    )

RouterConfig.$inject = ['$stateProvider']

module.exports = RouterConfig
