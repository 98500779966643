_             = require 'lodash'
Component     = require 'common/redux/component-function'
ConsentFields = require '../../components/consent/consent.fields'
Actions       = require './action.creators'
Selectors     = require './selectors'

AppDownloadController = Component(
  (state) ->
    result = _.assign(Selectors.displayValues(state), {
      options: Selectors.options(state),
      form: Selectors.form(state),
      consentMeta: Selectors.consentMeta
      successMessage: Selectors.successMessage(state)
    })

    result
, Actions)

# class AppDownloadController
#   constructor: (@$ngRedux) ->
#     _.forEach(Actions, (value, key) =>
#       @[key] = => @$ngRedux.dispatch(value(arguments...))
#     )
#
#   $onInit: ->
#     @$ngRedux.subscribe(=>
#       state = @$ngRedux.getState()
#       if state
#         for key, value of Selectors.displayValues(state)
#           @[key] = value
#
#         @options = Selectors.options(state)
#         @successMessage = Selectors.successMessage(state)
#         @form = Selectors.form(state)
#         @consentMeta = Selectors.consentMeta
#     )
#
# AppDownloadController.$inject = ['$ngRedux']

module.exports =
  controller: AppDownloadController
  template: require('./download.template.html')
