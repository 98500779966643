( ->
  # Allows one-way binding in the 'correct' direction
  # Use ng-value to set the current value from the controller, and on-<event>
  # to emit events when the DOM value changes, so we can update the store
  # (which then updates the controller)
  # Example:
  # class UserController
  #   constructor: () ->
  #     @name = 'Initial value'
  #
  #   nameChanged: (newName) ->
  #     @name = newName
  #
  # <input type="text" on-keyup="$ctrl.nameChanged" ng-value="$ctrl.name" />
  ['keyup', 'change'].forEach((event) ->
    name = 'on' + _.capitalize(event)

    directive = ($parse) ->
      restrict: 'A'
      link: (scope, element, attrs) ->
        element.on(event, ->
          scope.$apply( ->
            value = element.val()
            parsed = $parse(attrs[name])
            bound = parsed(scope)
            bound.call(scope.$ctrl, value)
          )
        )

    directive.$inject = ['$parse']

    angular.module('oneq.forms')
      .directive(name, directive)
  )

  checkedChanged = ($parse) ->
    restrict: 'A'
    link: (scope, element, attrs) ->
      element.on('change', ->
        checked = element.prop('checked')
        parsed = $parse(attrs.checkedChanged)
        bound = parsed(scope)
        bound.call(scope.$ctrl, checked)
      )

  checkedChanged.$inject = ['$parse']

  angular.module('oneq.forms')
    .directive('checkedChanged', checkedChanged)
)()
