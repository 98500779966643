_                     = require 'lodash'
Form                  = require 'common/forms/form.reducers'
Validators            = require 'common/forms/validators'
Fields                = require '../fields'
DemographicFields     = require '../demographic.fields'
Actions               = require '../actions'
ConsentFields         = require '../../components/consent/consent.fields'
Consent               = require '../../components/consent/consent.reducers'
Namespace             = require '../namespace'

validateUnder18Terms = (value, action) ->
  if action?.payload?.under18
    Validators.checked()(value)
  else
    []

module.exports = new Form.Builder()
  .withNamespace(Namespace)
  .withField(Fields.UNDER_18_TERMS, Form.InputField(false, [
    validateUnder18Terms
  ]))
  .withField(Fields.CHARITY, Form.SelectField(undefined, options=[], valueField='id', labelField='name'))
  .withField(Fields.FIRST_NAME, Form.InputField())
  .withField(Fields.LAST_NAME, Form.InputField())
  .withField(Fields.EMAIL, Form.InputField())
  .withField(Fields.COUNTRY, Form.SelectField())
  .withField(Fields.PHONE, Form.InputField())
  .withField(Fields.BIRTH_MONTH, Form.SelectField())
  .withField(Fields.BIRTH_DAY, Form.SelectField())
  .withField(Fields.BIRTH_YEAR, Form.SelectField())
  .withField(Fields.STREET_ADDRESS, Form.InputField())
  .withField(Fields.POSTAL_CODE, Form.InputField())
  .withField(Fields.MEMBER_PAYMENT, Form.SelectField())
  .withField(DemographicFields.GENDER, Form.SelectField())
  .withField(DemographicFields.MARITAL_STATUS, Form.SelectField())
  .withField(DemographicFields.NUMBER_OF_CHILDREN, Form.SelectField())
  .withField(DemographicFields.RACE, Form.SelectField())
  .withField(DemographicFields.EDUCATION_LEVEL, Form.SelectField())
  .withField(DemographicFields.EMPLOYMENT_STATUS, Form.SelectField())
  .withField(DemographicFields.INDUSTRY, Form.SelectField())
  .withField(DemographicFields.JOB_FUNCTION, Form.SelectField())
  .withField(DemographicFields.JOB_LEVEL, Form.SelectField())
  .withField(DemographicFields.HOUSEHOLD_INCOME, Form.SelectField())
  .withField(DemographicFields.POLITICAL_AFFILIATION, Form.SelectField())
  .withField(DemographicFields.RELIGION, Form.SelectField())
  .withField(DemographicFields.HOME_OWNERSHIP_STATUS, Form.SelectField())
  .withField(DemographicFields.VOTER_REGISTRATION, Form.SelectField())
  .withField(ConsentFields.Email.name, Consent.Email())
  .withField(ConsentFields.Phone.name, Consent.Phone())
  .withField(ConsentFields.Demo.name, Consent.Demo())
  .withField(ConsentFields.Terms.name, Consent.Terms())
  .withField(ConsentFields.PaypalUnder18.name, Consent.PaypalUnder18())
  .build()
