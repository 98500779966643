ErrorMessage = require 'common/util/error-message'

class VerificationCodeController
  constructor: (@ProfileService) ->
    @submitting = false
    @code = ''

  confirm: ->
    @submitting = true
    @ProfileService.confirmVerifcationCode(@code)
      .then(
        () => @close({ $value: true })
      ).catch(
        () => @close({ $value: false })
      ).finally( =>
        @submitting = false
      )

  cancel: ->
    @dismiss({ $value: false })

VerificationCodeController.$inject = ['ProfileService']

VerificationCodeLauncher = ($q, $uibModal) ->
  open = ->
    $uibModal.open(
      component: 'verificationCode'
      size: 'md'
    ).result

  launch: (verifyCode) ->
    $q.when(!verifyCode || open())

VerificationCodeLauncher.$inject = ['$q', '$uibModal']

module.exports =
  Component:
    bindings:
      close: '&'
      dismiss: '&'
    controller: VerificationCodeController
    template: require('./verification-code.template.html')
  Launcher: VerificationCodeLauncher
