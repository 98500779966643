{ combineReducers }   = require 'redux'
form                  = require './form/reducers'
download              = require './download/reducers'
Actions               = require './actions'

peopleAvailable = (state=0, action) ->
  if action.type == Actions.PEOPLE_AVAILABLE
    action.payload
  else
    state

module.exports = combineReducers({
  peopleAvailable,
  form,
  download
})
