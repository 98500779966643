angular       = require 'angular'
require 'imports-loader?angular=>window.angular!angular-ui-bootstrap'
NgRedux       = require 'ng-redux'
ReduxUiRouter = require 'redux-ui-router'

module.exports = angular.module('oneq.common.vendor', [
  'ui.bootstrap',
  NgRedux.default,
  ReduxUiRouter.default
]).name
