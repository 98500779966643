Component = require 'common/redux/component-function'
Selectors = require 'common/layout/selectors'

ParentalConsentSuccessController = Component((state) ->
  message: Selectors.currentParams(state).message || "Thank you!"
)

module.exports =
  controller: ParentalConsentSuccessController
  template: require('./parental-consent-success.template.html')
