angular = require 'angular'

RouterConfig = require './confirmation.router'
ConfirmationService = require './confirmation.service'

module.exports = angular.module('oneq.member.confirmation', [])
  .config(RouterConfig)
  .factory('ConfirmationService', ConfirmationService)
  .component('confirmationReferralBanner', require('./confirmation-referral-banner.component'))
  .component('confirmation', require('./confirmation.component'))
  .name
