module.exports =
  GENDER: 'gender'
  MARITAL_STATUS: 'maritalStatus'
  NUMBER_OF_CHILDREN: 'numberOfChildren'
  RACE: 'race'
  EDUCATION_LEVEL: 'educationLevel'
  EMPLOYMENT_STATUS: 'employmentStatus'
  INDUSTRY: 'industry'
  JOB_FUNCTION: 'jobFunction'
  JOB_LEVEL: 'jobLevel'
  HOUSEHOLD_INCOME: 'householdIncome'
  POLITICAL_AFFILIATION: 'politicalAffiliation'
  RELIGION: 'religion'
  HOME_OWNERSHIP_STATUS: 'homeOwnershipStatus'
  VOTER_REGISTRATION: 'voterRegistration'  
