ErrorMessage  = require 'common/util/error-message'
Component     = require 'common/redux/component-function'
Actions       = require './action.creators'
Selectors     = require './selectors'

ParentEmailController = Component(
  (state) ->
    parentEmail = Selectors.parentEmail(state)

    scope = { userEmail: Selectors.userEmail(state) }
    ['submitting', 'parentEmail', 'successMessage', 'errorMessage'].forEach((field) ->
      scope[field] = parentEmail[field] if parentEmail[field]?
    )
    scope
, Actions)

ParentEmailLauncher = ($uibModal, $ngRedux) ->
  launch: ->
    $ngRedux.dispatch(Actions.parentEmailOpening())

    $uibModal.open(
      component: 'parentalEmail'
      size: 'md'
    ).result

ParentEmailLauncher.$inject = ['$uibModal', '$ngRedux']

module.exports =
  Component:
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
    controller: ParentEmailController
    template: require('./parent-email.template.html')
  Launcher: ParentEmailLauncher
