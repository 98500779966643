angular = require 'angular'

MemberConsentModule = require '../../components/consent/consent.module'
RouterConfig = require './register-form.router'

module.exports = angular.module('oneq.member.register.form', [
  MemberConsentModule
]).config(RouterConfig)
  .component('registerForm', require('./register-form.component'))
  .name
