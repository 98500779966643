module.exports =
  required: (message = 'required') -> (value, action) ->
    if !!value
      []
    else
      [message]
  gte: (min, message = 'min') -> (value, action) ->
    if value >= min
      []
    else
      [message]
  checked: (message = 'required') -> (value, action) ->
    if value == true
      []
    else
      [message]
