_                     = require 'lodash'
{ combineReducers }   = require 'redux'
Form                  = require 'common/forms/form.reducers'
FormActions           = require 'common/forms/form.actions'
ConsentFields         = require '../../components/consent/consent.fields'
Consent               = require '../../components/consent/consent.reducers'
Actions               = require './actions'
Fields                = require './fields'
Namespace             = require './namespace'

form = new Form.Builder()
  .withNamespace(Namespace)
  .withField(Fields.DOWNLOAD_COUNTRY, Form.SelectField())
  .withField(Fields.MOBILE, Form.InputField())
  .withField(ConsentFields.Phone.name, Consent.Phone())
  .build()

successMessage = (state='', action) ->
  if action.type == Actions.SUBMIT
    action.payload
  else state

module.exports = combineReducers({
  successMessage,
  form
})
