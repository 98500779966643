angular = require 'angular'

CountryModule = require 'common/country.module'
MemberConsentModule = require '../../components/consent/consent.module'
RouterConfig = require './download.router'

module.exports = angular.module('oneq.member.register.download', [
  CountryModule,
  MemberConsentModule
]).config(RouterConfig)
  .component('downloadForm', require('./download.component'))
  .name
