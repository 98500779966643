_                   = require 'lodash'
{ combineReducers } = require 'redux'
Actions             = require '../actions'
FormActions         = require 'common/forms/form.actions'
DemographicFields   = require '../demographic.fields'
{insertIntoArray, removeFromArray, updatedArray} = require 'common/redux/base.reducer'

defaultChildInfo = (atIndex) ->
    index: atIndex
    age:
      month: undefined
      year: undefined
    gender: undefined


numberOfChildrenPerValue = (numberOfChildrenDemo) ->
  switch numberOfChildrenDemo
    when "no-children"
      0
    when "one-child"
      1
    when "two-children"
      2
    when "three-children"
      3
    when "four-or-more-children"
      4
    else
      0

stateForNumberOfChildren = (numberOfChildren, currentState = []) ->
    _.range(0, newNumberOfChildren).map((idx) ->
      defaultChildAge(atIndex)
    )

childAgeReducer = (state = {}, action) ->
  switch action.type
    when Actions.CHILD_AGE_YEAR_CHANGED
      {year} = action.payload
      _.assign({}, state, {year})

    when Actions.CHILD_AGE_MONTH_CHANGED
      {month} = action.payload
      _.assign({}, state, {month})

    else
      state

childInfoReducer = (state = {}, action) ->
  switch action.type
    when Actions.CHILD_AGE_YEAR_CHANGED, Actions.CHILD_AGE_MONTH_CHANGED
      _.assign({}, state, {age: childAgeReducer(state.age, action)}) 
    when Actions.CHILD_GENDER_CHANGED
      {gender} = action.payload
      _.assign({}, state, {gender})
    else
      state

childInfosReducer = (state = [], action) ->
  switch action.type
    when Actions.CHILD_INFO_LOADED
      {childInfos} = action.payload
      _.map(childInfos, (childInfo, index) ->
        _.assign({},childInfo,{index: index})
      )

    when Actions.CHILD_INFO_ADDED
      {atIndex} = action.payload
      _.concat(state, defaultChildInfo(atIndex))

    when Actions.CHILD_INFO_REMOVED
      {atIndex} = action.payload
      removeFromArray(state, atIndex)

    when Actions.CHILD_AGE_MONTH_CHANGED, Actions.CHILD_AGE_YEAR_CHANGED, Actions.CHILD_GENDER_CHANGED
      {atIndex} = action.payload
      updatedArray(state, atIndex, _.assign({}, childInfoReducer(state[atIndex], action)))

    when FormActions.FORM_FIELD_CHANGED, FormActions.FORM_FIELD_INIT
      {field, value} = action.payload
      if field is DemographicFields.NUMBER_OF_CHILDREN
        asANumber = numberOfChildrenPerValue(value)
        numberOfChildren = 
          if (asANumber is 4) then Math.max(asANumber, _.size(state))
          else asANumber
        _.range(0, numberOfChildren).map((idx) ->
          state[idx] || defaultChildInfo(idx)
        )
      else state

    else
      state

module.exports = childInfosReducer
