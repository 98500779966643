Component = require 'common/redux/component-function'

RegisterController = Component(
  (state) ->
    peopleAvailable: state.member.register.peopleAvailable
)

module.exports =
  controller: RegisterController
  template: require('./register.template.html')
