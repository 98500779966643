Security = require './security.models'

current = undefined

SecurityService = (OneQClient, $q) ->

  fetch = ->
    deferred = $q.defer()
    current = deferred.promise
    OneQClient.get('/web/api/security/v1/currentSession')
      .then((response) -> Security.Session.Factory(response.data))
      .then((session) ->
        deferred.resolve(session)
        session
      )

  # pull session once on the page, changes require a page reload or navigation
  currentSession: ->
    unless current? then fetch()
    else current

SecurityService.$inject = ['OneQClient', '$q']

module.exports = SecurityService
