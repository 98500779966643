_             = require 'lodash'
Component     = require 'common/redux/component-function'
Actions       = require './parental-consent.action.creators'
ConsentFields = require '../components/consent/consent.fields'
Selectors     = require './parental-consent.selectors'

ParentalConsentFormController = Component(
  (state) ->
    _.assign(Selectors.displayValues(state), {
      form: Selectors.form(state),
      consentsGiven: Selectors.consentsGiven(state)
      childName: Selectors.childName(state)
      childEmail: Selectors.childEmail(state)
      consentMeta: [
        ConsentFields.Phone,
        ConsentFields.Email,
        ConsentFields.PaypalUnder18,
        ConsentFields.Demo,
        ConsentFields.Terms
      ]
    })
, Actions)

module.exports =
  controller: ParentalConsentFormController
  template: require('./parental-consent.template.html')
