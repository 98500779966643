_ = require 'lodash'

allForResponse = (response) ->
  if _.isString(response.data)
    [response.data]
  else if _.isObject(response.data) && _.has(response.data, 'message')
    response.data.message
  else
    fieldErrors = _.flatten(_.values(response.data?.fieldErrors || {}))
    globalErrors = response.data?.globalErrors || []
    allErrors = globalErrors.concat(fieldErrors)
      .map((err) -> err.message)

forResponse = (response) ->
  allForResponse(response)[0]

module.exports =
  allForResponse: allForResponse
  forResponse: forResponse
