_                 = require 'lodash'
Component         = require 'common/redux/component-function'
ConsentFields     = require '../../components/consent/consent.fields'
Actions           = require './action.creators'
Selectors         = require './selectors'

RegisterFormController = Component(
  (state) ->
    _.assign(Selectors.displayValues(state), {
      options: Selectors.options(state),
      form: Selectors.form(state),
      consentMeta: Selectors.consentMeta
    })
, Actions)

module.exports =
  controller: RegisterFormController
  template: require('./register-form.template.html')
