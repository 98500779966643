_                   = require 'lodash'
{ createSelector }  = require 'reselect'

confirmation = (state) -> state.member.confirmation
confirmationField = (field) ->
  createSelector(
    confirmation,
    (confirmation) -> confirmation[field]
  )

completeProfile = confirmationField('completeProfile')
earningPotential = confirmationField('earningPotential')
appDownloaded = confirmationField('appDownloaded')
minimumPaymentSelected = confirmationField('minimumPaymentSelected')
paymentsSentTo = confirmationField('paymentsSentTo')
registeredWithFacebook = confirmationField('registeredWithFacebook')
downloadLinkSent = confirmationField('downloadLinkSent')
downloadErrorMessage = confirmationField('downloadErrorMessage')
referral = confirmationField('referral')

progressStyle = createSelector(
  earningPotential,
  (earningPotential) ->
    width = if earningPotential < 1 then 0 else earningPotential

    width: "#{width}%"
)

module.exports = {
  completeProfile,
  earningPotential,
  appDownloaded,
  minimumPaymentSelected,
  paymentsSentTo,
  registeredWithFacebook,
  downloadLinkSent,
  downloadErrorMessage,
  referral,
  progressStyle
}
