_ = require 'lodash'

DemographicDataMap = (demographicData) ->
  dataMapFor: (categoryName) ->
    filteredForDemographics =  _.find(demographicData, (d) -> d.category is categoryName)
    filteredForDemographics?.dataList

DemographicsService = (OneQClient) ->
  baseUrl = "/web/api/demographics/v1"

  service =
    demographicData: () ->
      OneQClient.get("#{baseUrl}/demographicCount")
        .then((response) -> DemographicDataMap(response.data))

    peopleAvailable: () ->
      OneQClient.get("#{baseUrl}/count")
        .then((response) -> response.data)

  service

DemographicsService.$inject = [ 'OneQClient' ]

module.exports = DemographicsService
