_                   = require 'lodash'
Actions             = require './form.actions'

validateField = (rules) -> (value, action) ->
  errors = _.flatMap(rules, (validate) -> validate(value, action))
  isValid = errors.length == 0

  { errors, isValid }

InputField = (initialValue, rules = []) ->
  validated = validateField(rules)

  {errors, isValid} = validated(initialValue)
  initialState = {
    value: initialValue,
    touched: false,
    errors,
    isValid
  }

  (state=initialState, action) ->
    if action.type in [Actions.FORM_FIELD_INIT, Actions.FORM_FIELD_CHANGED]
      {value} = action.payload
      {errors, isValid} = validated(value, action)

      { value, errors, isValid, touched: action.type == Actions.FORM_FIELD_CHANGED }
    else state

SelectField = (initialValue, options=[], valueField='value', labelField='label', rules=[]) ->
  validated = validateField(rules)
  {errors, isValid} = validated(initialValue)
  initialState = {
    value: initialValue,
    touched: false,
    options,
    valueField,
    labelField,
    errors,
    isValid
  }

  (state=initialState, action) ->
    switch action.type
      when Actions.FORM_FIELD_INIT
        {options, value} = action.payload
        {errors, isValid} = validated(value, action)
        _.assign({}, state, {options, value, errors, isValid})

      when Actions.FORM_FIELD_CHANGED
        {value} = action.payload
        {errors, isValid} = validated(value, action)
        _.assign({}, state, {touched: true, value, errors, isValid})

      else state

Builder = () ->
  namespaces = []
  fieldReducers = {}
  validators = []
  initialState =
    fields: {}
    submitted: false

  validated = (state, action) ->
    fieldsValid = _.every(state.fields, (field, name) -> field.isValid)
    errors = _.flatMap(validators, (validate) -> validate(state.fields, action))
    isValid = errors.length == 0 && fieldsValid

    _.assign({}, state, {errors, isValid})

  # Use namespaces to avoid handling actions for forms we don't care about
  @withNamespace = (namespace) ->
    namespaces.push(namespace)
    @

  @withField = (name, reducer) ->
    fieldReducers[name] = reducer
    # Get the initial state from the passed-in reducer and set it as the state of the field
    initialState.fields[name] = reducer(undefined, {type: 'INITIAL_STATE'})
    @

  @withValidator = (validator) ->
    validators.push(validator)
    @

  @build = ->
    (state=validated(initialState), action) ->
      switch
        when action.type in [Actions.FORM_FIELD_INIT, Actions.FORM_FIELD_CHANGED] && action.payload.namespace in namespaces
          {field} = action.payload

          updatedField = {}
          updatedField[field] = fieldReducers[field](state.fields[field], action)

          updatedFields = _.assign({}, state, {
            fields: _.assign({}, state.fields, updatedField)
          })
          
          validated(updatedFields)

        when action.type == Actions.SUBMITTING_FORM && action.payload.namespace in namespaces
          _.assign({}, state, {submitted: true})

        else state
  @

module.exports = {
  InputField,
  SelectField,
  Builder
}
